import React, { Component } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Button, Badge, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Header, SidebarNav, Footer, PageContent, Avatar, Chat, PageAlert, Page, PopUp } from '../components';
import loginImage from '../assets/custom/step_first_logo.png';
import Nav from '../_nav';
import routes from '../views';
import ContextProviders from '../components/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../components/helpers/handleTabAccessibility';
import Cookies from 'js-cookie';

const MOBILE_SIZE = 992;

export default class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      isLoggedIn: !!Cookies.get('token'),
      nav: {
        top: [...((Nav[Cookies.get('role')] || {}).top || [])],
        bottom: [...((Nav[Cookies.get('role')] || {}).bottom || [])],
        // TODO: comment template b4 launch
        // top: [...((Nav[Cookies.get('role')] || {}).top || []), ...Nav['template'].top],
        // bottom: [...((Nav[Cookies.get('role')] || {}).bottom || []), ...Nav['template'].bottom],
      },
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSideCollapse = () => {
    this.setState((prevState) => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };

  render() {
    const { sidebarCollapsed, nav } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';

    if (!this.state.isLoggedIn) {
      return <Redirect from="/" to="/login" />;
    }
    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <PopUp />
          <div className="app-body">
            <SidebarNav
              nav={nav}
              logo={loginImage}
              logoText=" Step First Limited"
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
              {...this.props}
            />
            <Page>
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                {...this.props}
              >
                <HeaderNav />
              </Header>
              <PageContent>
                <Switch>
                  {routes.map((page, key) => (
                    <Route path={page.path} component={page.component} key={key} />
                  ))}
                  <Redirect from="/" to="/home" />
                </Switch>
              </PageContent>
            </Page>
          </div>
          {/* <Footer>
            <span>Copyright © 2019 Nice Dash. All rights reserved.</span>
            <span>
              <a href="#!">Terms</a> | <a href="#!">Privacy Policy</a>
            </span>
            <span className="ml-auto hidden-xs">
              Made with{' '}
              <span role="img" aria-label="taco">
                🌮
              </span>
            </span>
          </Footer> */}
          {/* <Chat.Container>
            {this.state.showChat1 && (
              <Chat.ChatBox name="Messages" status="online" image={avatar1} close={this.closeChat} />
            )}
          </Chat.Container> */}
        </div>
      </ContextProviders>
    );
  }
}

function HeaderNav() {
  const history = useHistory();
  const _logout = () => {
    Cookies.remove('token');
    Cookies.remove('role');
    Cookies.remove('userId');
    Cookies.remove('username');
    history.push('/login');
  };
  return (
    <React.Fragment>
      <NavItem className="d-flex align-items-center mr-2">
        <Avatar
          initials={Cookies.get('username') && Cookies.get('username')[0].toUpperCase()}
          size="small"
          color="#014872"
          className="mr-2 rounded-circle"
        />
        <div>{Cookies.get('username')}</div>
      </NavItem>
      <NavItem>
        <form className="form-inline" onSubmit={_logout}>
          {/* <input className="form-control mr-sm-1" type="search" placeholder="Search" aria-label="Search" /> */}
          <Button type="submit" className="d-none d-sm-block">
            <i className="fa fa-sign-out" />
            &nbsp;&nbsp; Log Out
          </Button>
        </form>
      </NavItem>
    </React.Fragment>
  );
}

import React, { createContext, useState, useRef } from 'react';

const PopUpContext = createContext();

export function ModalPopUpProvider(props) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const resolver = useRef();

  const onOpen = (message) => {
    setMessage(message);
    setShow(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const onConfirm = () => {
    resolver.current && resolver.current(true);
    setShow(false);
  };

  const onCancel = () => {
    resolver.current && resolver.current(false);
    setShow(false);
    setMessage('');
  };

  return (
    <PopUpContext.Provider
      value={{
        show,
        message,
        onOpen,
        onConfirm,
        onCancel,
      }}
    >
      {props.children}
    </PopUpContext.Provider>
  );
}

export default PopUpContext;

import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import ModalPopUpContext from './PopUpContext';

export default function ModalPopUp() {
  return (
    <ModalPopUpContext.Consumer>
      {(context) => {
        return (
          <Modal centered={true} isOpen={context.show} toggle={() => context.onCancel()}>
            <ModalHeader>
              <b>Confirmation</b>
            </ModalHeader>
            <ModalBody>{context.message}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => context.onConfirm()}>
                Confirm
              </Button>
              <Button color="danger" onClick={() => context.onCancel()}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </ModalPopUpContext.Consumer>
  );
}

const template = {
  top: [
    {
      name: 'Home',
      url: '/home',
      icon: 'Home',
    },
    {
      name: 'UI Elements',
      icon: 'Layers',
      children: [
        {
          name: 'Buttons',
          url: '/elements/buttons',
        },
        {
          name: 'Grid',
          url: '/elements/grid',
        },
        {
          name: 'Alerts',
          url: '/elements/alerts',
        },
        {
          name: 'Typography',
          url: '/elements/typography',
        },
        {
          name: 'Cards',
          url: '/elements/cards',
        },
        {
          name: 'Tabs',
          url: '/elements/tabs',
        },
        {
          name: 'Tables',
          url: '/elements/tables',
        },
        {
          name: 'Breadcrumbs',
          url: '/elements/breadcrumbs',
        },
        {
          name: 'Forms',
          url: '/elements/forms',
        },
        {
          name: 'Modals',
          url: '/elements/modals',
        },
        {
          name: 'Loaders',
          url: '/elements/loaders',
        },
        {
          name: 'Avatars',
          url: '/elements/avatars',
        },
        {
          name: 'Progress Bars',
          url: '/elements/progressbars',
        },
        {
          name: 'Pagination',
          url: '/elements/pagination',
        },
      ],
    },
    {
      name: 'Pages',
      icon: 'File',
      children: [
        {
          name: 'Blank',
          url: '/pages/blank',
        },
        {
          name: 'Sub Navigation',
          url: '/pages/subnav',
        },
        {
          name: '404',
          url: '/pages/404',
        },
      ],
    },
    {
      name: 'Apps',
      icon: 'Cloud',
      children: [
        {
          name: 'Analytics',
          url: '/apps/analytics',
        },
        {
          name: 'Invoice',
          url: '/apps/invoice',
        },
        {
          name: 'Activity Feed',
          url: '/apps/feed',
        },
        {
          name: 'CMS',
          url: '/apps/cms',
        },
      ],
    },
    {
      divider: true,
    },
    {
      name: 'Widgets',
      url: '/widgets',
      icon: 'Package',
      badge: {
        text: 'NEW',
      },
    },
  ],
  bottom: [
    {
      name: 'Get Vibe',
      url: 'https://github.com/NiceDash/Vibe',
      icon: 'GitHub',
      external: true,
      target: '_blank',
    },
    {
      name: 'Account',
      url: '/dashboard',
      icon: 'User',
      badge: {
        variant: 'success',
        text: '3',
      },
    },
  ],
};

// broker nav here (full function)
const broker = {
  top: [
    {
      name: 'Create Quotation',
      url: '/project/create',
      icon: 'FilePlus',
    },
    {
      name: 'Vendor Job List',
      url: '/vendor/jobList',
      icon: 'File',
    },
    {
      name: 'Payslip List',
      url: '/payslip/list',
      icon: 'DollarSign',
    },
    {
      name: 'Project Mgmt.',
      icon: 'Briefcase',
      children: [
        {
          name: 'Enquiry',
          url: '/project/enquiry',
        },
        {
          name: 'Project',
          url: '/project/list',
        },
        {
          name: 'Salesperson',
          url: '/salesproject/list',
        },
        {
          name: 'Vendor',
          url: '/vendorproject/list',
        },
      ],
    },
    {
      name: 'Users',
      url: '/user/list',
      icon: 'Users',
    },
    {
      name: 'Content Mgmt.',
      icon: 'Settings',
      children: [
        {
          name: 'Basic Config',
          url: '/content/basic',
        },
        {
          name: 'Service Config',
          url: '/content/service',
        },
      ],
    },
  ],
  bottom: [],
};

// vendor nav here
const vendor = {
  top: [
    {
      name: 'Vendor Job List',
      url: '/vendor/jobList',
      icon: 'File',
    },
    {
      name: 'Payslip List',
      url: '/payslip/list/vendor',
      icon: 'DollarSign',
    },
  ],
  bottom: [],
};

// admin nav here
const admin = {
  top: [
    {
      name: 'Create Quotation',
      url: '/project/create',
      icon: 'FilePlus',
    },
    {
      name: 'Vendor Job List',
      url: '/vendor/jobList',
      icon: 'File',
    },
    {
      name: 'Payslip List',
      url: '/payslip/list',
      icon: 'DollarSign',
    },
    {
      name: 'Project Mgmt.',
      icon: 'Briefcase',
      children: [
        {
          name: 'Enquiry',
          url: '/project/enquiry',
        },
        {
          name: 'Project',
          url: '/project/list',
        },
        {
          name: 'Salesperson',
          url: '/salesproject/list',
        },
        {
          name: 'Vendor',
          url: '/vendorproject/list',
        },
      ],
    },
    {
      name: 'Users',
      url: '/user/list',
      icon: 'Users',
    },
    {
      name: 'Content Mgmt.',
      icon: 'Settings',
      children: [
        {
          name: 'Basic Config',
          url: '/content/basic',
        },
        {
          name: 'Service Config',
          url: '/content/service',
        },
      ],
    },
  ],
  bottom: [],
};

const root = {
  top: [
    {
      name: 'Users',
      url: '/user/list',
      icon: 'Users',
    },
    {
      name: 'Content Mgmt.',
      icon: 'Settings',
      children: [
        {
          name: 'Basic Config',
          url: '/content/basic',
        },
        {
          name: 'Service Config',
          url: '/content/service',
        },
      ],
    },
  ],
  bottom: [],
};
export default {
  template,
  broker,
  vendor,
  admin,
  root,
};

import React from 'react';
import { PageLoaderProvider } from '../../PageLoader/PageLoaderContext';
import { PageAlertProvider } from '../../PageAlert/PageAlertContext';
import { ModalPopUpProvider } from '../../PopUp/PopUpContext';

export default function ContextProviders({ children }) {
  return (
    <PageLoaderProvider>
      <PageAlertProvider>
        <ModalPopUpProvider>{children}</ModalPopUpProvider>
      </PageAlertProvider>
    </PageLoaderProvider>
  );
}

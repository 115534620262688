import React, { useState, useEffect } from 'react';
import { Card, CardBody, Button, Input, Form } from 'reactstrap';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

import loginImage from '../assets/custom/logo.png';
import { fetchWithPost } from '../httpHelper';

export default function LoginPage() {
  const history = useHistory();

  const [isLoggedIn] = useState(!!Cookies.get('token'));
  const [email, setEmail] = useState(Cookies.get('email') || '');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(!!Cookies.get('email'));
  const [errorMsg, setErrorMsg] = useState('');

  const _login = async e => {
    e.preventDefault();
    Cookies.remove('email');
    if (rememberMe) Cookies.set('email', email);
    if (email && password) {
      const result = await fetchWithPost('/auth/login', {
        email,
        password,
        platform: 'dashboard'
      });
      if (result.status === 200) {
        Cookies.set('token', result.data.tokens.access.token);
        Cookies.set('role', result.data.user.role);
        Cookies.set('username', result.data.user.name);
        Cookies.set('userId', result.data.user.id);
        history.push('/');
      } else if (result.status) {
        setErrorMsg(result.data.message);
      }
    } else {
      setErrorMsg('Please enter email and password');
    }
  };
  useEffect(() => {
    if (isLoggedIn === true) {
      history.push('/');
    }
  }, []);

  return (
    <div className="login-page-wrapper">
      <div className="login-wrap text-center">
        <Card>
          <CardBody>
            <div className="mb-4">
              <img alt="Step First Limited" src={loginImage} className="login-img mb-2" />
              <h2>Step First Dashboard</h2>
            </div>
            <Form onSubmit={_login}>
              <div className="mb-2">
                <Input placeholder="Email address" value={email} onChange={v => setEmail(v.target.value)} />
              </div>
              <div className="mb-2">
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={v => setPassword(v.target.value)}
                />
              </div>
              <div className="mb-2 ml-4 text-left">
                <Input type="checkbox" checked={rememberMe} onChange={v => setRememberMe(v.target.checked)} />
                Remember Me
                <br />
              </div>
              <div className="mb-2 text-left">
                <span className="text-danger">{errorMsg}</span>
              </div>
              <Button type="submit" block>
                Log in
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { Avatar } from '../../';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Form } from 'reactstrap';

function ChatSubText({ message, right }) {
  return (
    <div className={`ChatSubText text-muted ${right ? 'pull-right' : 'pull-left'}`}>
      <i className="fa fa-ok text-success" />
      {message}
    </div>
  );
}

function ChatMessage({ message, subText, right }) {
  return (
    <div className={`clear p-b mr-2 ml-2 ${right ? 'pull-right' : 'pull-left'}`}>
      <div>
        <div className="p-a-xs p-l-sm p-r-sm bg-primary text-white inline-block rounded text-left">{message}</div>
      </div>
      <ChatSubText message={subText} right={right} />
    </div>
  );
}

function ChatDisplay({ height, msg }) {
  const style = {
    height: height ? height : 200,
    overflowY: 'auto',
  };

  const messagesEndRef = useRef();
  useEffect(() => {
    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  }, [messagesEndRef]);

  return (
    <div className="ChatDisplay p-a" style={style} ref={messagesEndRef}>
      {msg && msg.length > 0
        ? msg.map((v, i) => {
            const right = Cookies.get('userId') === v.userId.id;
            return (
              <div key={i} style={{ display: 'inline-block', width: '100%' }}>
                <Avatar
                  // initials={Cookies.get('username') && Cookies.get('username')[0].toUpperCase()}
                  initials={v.userId.name[0].toUpperCase()}
                  size="small"
                  color="#014872"
                  className={`${right ? 'pull-right' : 'pull-left'} rounded-circle`}
                />
                <ChatMessage
                  message={v.content}
                  subText={moment(v.createdDatetime).fromNow()}
                  className={right ? 'pull-right' : 'pull-left'}
                  right={right}
                />
              </div>
            );
          })
        : null}
    </div>
  );
}

function ChatInput({ onSend }) {
  const [msg, setMsg] = useState('');
  const _send = e => {
    e.preventDefault();
    if (msg) onSend(msg);
    setMsg('');
  };
  return (
    <Form onSubmit={_send}>
      <div className="p-a-sm b-t bg-white">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={msg}
            onChange={e => setMsg(e.target.value)}
          />
          <span className="input-group-btn m-l-sm">
            <button className="btn bg-white b-a no-shadow" type="submit">
              <i className="fa fa-send" />
              <span className="sr-only">Send Message</span>
            </button>
          </span>
        </div>
      </div>
    </Form>
  );
}

export default function ChatBox({ msg, onSend }) {
  return (
    <div className="Chat-wrap">
      <ChatDisplay msg={msg} />
      <ChatInput onSend={text => onSend(text)} />
    </div>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  ButtonGroup,
  Row,
  Col,
} from 'reactstrap';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import Cookies from 'js-cookie';
import { Loader } from '../../components';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';

export default function VendorJobListPage() {
  let { path, url } = useRouteMatch();
  const rootName = 'Vendor Job List';
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <JobList rootPath={url} rootName={rootName} />
        </Route>
        <Route path={`${path}/:id`}>
          <JobDetail rootPath={url} rootName={rootName} />
        </Route>
      </Switch>
    </Router>
  );
}

function JobList({ rootPath, rootName }) {
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const role = Cookies.get('role');
  const [data, setData] = useState([]);
  const columns = [
    '#',
    'Name',
    'Price',
    'Due Date',
    role === 'vendor' ? 'Assign From' : 'Assign To',
    'Create Date',
    'Confirm or Reject Date',
    'Status',
    'Remark',
  ];
  const options = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      console.log(tasks[rowMeta.dataIndex]);
      history.push(`${rootPath}/${tasks[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/projects/getTaskList');
    if (result) {
      setTasks(result.data);
      setData(
        result.data.map((v, i) => [
          i + 1,
          v.projectDetailId.name,
          v.assignPrice,
          v.dueDate && moment(v.dueDate).format('YYYY-MM-DD'),
          role === 'vendor' ? v.assignFrom.name : v.assignTo.name,
          v.createdDatetime && moment(v.createdDatetime).format('YYYY-MM-DD'),
          v.confirmOrRejectDatetime && moment(v.confirmOrRejectDatetime).format('YYYY-MM-DD'),
          _.startCase(v.status),
          v.content,
        ])
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function JobDetail({ rootPath, rootName }) {
  let { id } = useParams();
  let { url } = useRouteMatch();

  //context
  const alertContext = useContext(PageAlertContext);

  //data
  const [taskDetail, setTaskDetail] = useState({});

  //inputs
  const [reason, setReason] = useState('');
  const [content, setContent] = useState('');

  const fetchApi = async () => {
    const result = await fetchWithGet('/projects/getTaskDetail', { projectDetailId: id });
    if (result.status === 200) {
      setTaskDetail(result.data);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const _confirmOrReject = async (status) => {
    if (status === 'rejected' && _.isEmpty(reason)) {
      alertContext.setAlert('Reason cannot be empty', 'danger');
      return;
    }
    const result = await fetchWithPost('/projects/updateTaskStatus', {
      projectTaskId: taskDetail.projectDetailId.projectTaskId,
      status: status,
      projectDetailId: taskDetail.projectDetailId.id,
      content: reason,
    });

    if (result.status === 200) {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
      setReason('');
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };

  const _updateTaskStatus = async () => {
    if (_.isEmpty(content)) {
      alertContext.setAlert('Please enter content', 'danger');
      return;
    }
    const result = await fetchWithPost('/projects/addProjectStatus', {
      projectId: taskDetail.projectDetailId.projectId,
      parentProjectStatusId: taskDetail.projectStatusId.id,
      content,
      statusType: 'text',
    });

    if (result.status === 200) {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
      setContent('');
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${id}`}>{!_.isEmpty(taskDetail) ? taskDetail.name : id}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      {_.isEmpty(taskDetail) ? (
        <div />
      ) : (
        <Row>
          <Col>
            <Card>
              <CardHeader>Task Detail</CardHeader>
              <CardBody>
                Task Name: {taskDetail.projectDetailId.name}
                <br />
                Qty: {taskDetail.projectDetailId.qty}
                <br />
                Price: ${taskDetail.assignPrice}
                <br />
                Due Date: {taskDetail.dueDate && moment(taskDetail.dueDate).format('YYYY-MM-DD')}
                <br />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>Task Status</CardHeader>
              <CardBody>
                <Timeline>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {taskDetail.projectStatusId.status.en}
                      <br />
                      {taskDetail.projectStatusId.content ? (
                        <>
                          {taskDetail.projectStatusId.content} <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {moment(taskDetail.projectStatusId.createdDatetime).format('YYYY-MM-DD HH:mm')}
                    </TimelineContent>
                  </TimelineItem>
                  {taskDetail.projectStatusId.subProjectStatusList.map((v, i) => (
                    <TimelineItem key={i}>
                      <TimelineSeparator>
                        <TimelineDot />
                        {i === taskDetail.projectStatusId.subProjectStatusList.length - 1 ? null : (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent>
                        {v.status.en}
                        <br />
                        {v.content ? (
                          <>
                            {v.content} <br />
                          </>
                        ) : (
                          <></>
                        )}
                        {moment(v.createdDatetime).format('YYYY-MM-DD hh:mm')}
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </CardBody>
            </Card>
          </Col>
          <Col>
            {taskDetail.status === 'pending' ? (
              <Card>
                <CardBody>
                  <FormGroup>
                    <Input
                      type="textarea"
                      value={reason}
                      placeholder={'Reason'}
                      className="mb-2"
                      onChange={(e) => setReason(e.target.value)}
                    />
                    <ButtonGroup style={{ width: '100%' }}>
                      <Button color="primary" onClick={() => _confirmOrReject('inProgress')}>
                        Accept
                      </Button>
                      <Button color="danger" onClick={() => _confirmOrReject('rejected')}>
                        Reject
                      </Button>
                    </ButtonGroup>
                  </FormGroup>
                </CardBody>
              </Card>
            ) : (
              <div>
                <Card>
                  <CardHeader>Update status</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Input
                        type="textarea"
                        value={content}
                        placeholder={'Content'}
                        className="mb-2"
                        onChange={(e) => setContent(e.target.value)}
                      />
                      <Button color="primary" onClick={() => _updateTaskStatus()} block>
                        Update
                      </Button>
                    </FormGroup>
                  </CardBody>
                </Card>
                {taskDetail.status === 'inProgress' && taskDetail.assignFrom.id === Cookies.get('userId') ? (
                  <Card>
                    <CardHeader>Please confirm the task is completed</CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Button color="primary" onClick={() => _confirmOrReject('completed')} block>
                          Completed
                        </Button>
                      </FormGroup>
                    </CardBody>
                  </Card>
                ) : (
                  <div />
                )}
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  );
}

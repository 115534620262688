import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { fetchWithGet } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { Loader } from '../../components';
import ProjectDetail from './widgets/ProjectDetail';

export default function SalesProjectListPage() {
  let { path, url } = useRouteMatch();
  const rootName = 'Sales List';
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <UserList rootPath={url} rootName={rootName} />
        </Route>
        <Route exact path={`${path}/:userId`}>
          <ProjectList rootPath={url} rootName={rootName} />
        </Route>
        <Route path={`${path}/:userId/:id`}>
          <ProjectDetail rootPath={url} rootName={rootName} />
        </Route>
      </Switch>
    </Router>
  );
}

function UserList({ rootPath, rootName }) {
  const history = useHistory();
  const [uesrs, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const columns = ['#', 'Name', 'Role', 'Company Name', 'Phone No', 'On Going', 'No. of Project'];
  const options = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${uesrs[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/users', {
      active: true,
      deleted: false,
      sortBy: 'email:asc',
    });

    if (result) {
      const filteredList = result.data.filter(
        (v) =>
          v.email !== 'root@borderlesshk.com' &&
          (v.role === 'senior_sales' || v.role === 'sales' || v.role === 'broker')
      );
      setUsers(filteredList);
      setData(
        filteredList.map((v, i) => [i + 1, v.name, _.startCase(v.role), v.companyName, v.tel, v.onGoing, v.noOfProject])
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function ProjectList({ rootPath, rootName }) {
  const history = useHistory();
  let { userId } = useParams();
  let { url } = useRouteMatch();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const columns = [
    '#',
    'Enquiry No.',
    'Quotation No.',
    'Invoice No.',
    'Name',
    'Price',
    'Paid',
    'Outstanding',
    'Prepared By',
    'Last Update',
    'Due Date',
    'Status',
  ];
  const options = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${userId}/${projects[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/projects/getProjectList');
    if (result) {
      const projectList = result.data.results.filter(
        (v) => v.stage !== 'enquiry' && v.stage !== 'enquiry_failed' && v.userId.id === userId
      );

      setProjects(projectList);
      setData(
        projectList.map((v, i) => [
          i + 1,
          v.enqNo || '',
          v.quoNo || '',
          v.invNo || '',
          `${v.name.en} ${v.name.zh}`,
          v.totalAmount,
          v.totalAmount - (v.outstanding || v.totalAmount),
          v.outstanding || v.totalAmount,
          v.userId.name,
          v.lastUpdateStatusTime && moment(v.lastUpdateStatusTime).format('YYYY-MM-DD hh:mm:ss'),
          v.estEndDate && moment(v.estEndDate).format('YYYY-MM-DD'),
          _.startCase(v.stage),
        ])
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${rootPath}/${userId}`}>{projects.length > 0 ? projects[0].userId.name : userId}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

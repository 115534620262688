import React, { useState, useEffect, useContext } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import FA from 'react-fontawesome';
import moment from 'moment';
import update from 'immutability-helper';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import Select from 'react-select';

export default function CreateProjectFormPage() {
  let { path, url } = useRouteMatch();
  const rootName = 'Create Quotation';
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <CreateProjectForm rootPath={url} rootName={rootName} />
        </Route>
      </Switch>
    </Router>
  );
}

export function CreateProjectForm({ rootPath, rootName }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();
  //context
  const alertContext = useContext(PageAlertContext);

  //data
  const [taskList, setTaskList] = useState([]);
  const [config, setConfig] = useState({});
  const [types] = useState([
    { value: 'company', label: 'Company' },
    { value: 'customer', label: 'Customer' },
  ]);
  const [users, setUsers] = useState([]);

  //inputs
  const [quoNo, setQuoNo] = useState('');
  const [data, setData] = useState({
    quoDate: Date.now(),
    estEndDate: Date.now(),
    name: {
      en: '',
      zh: '',
    },
    contactPerson: '',
    phoneNo: '',
    address: '',
    email: '',
    type: types[0],
    remark: '',
    totalAmount: '',
    preparedBy: '',
  });
  const [projectDetails, setProjectDetails] = useState([]);

  const fetchApi = async () => {
    const configRes = await fetchWithGet('/config/getConfig');
    if (configRes.status === 200) {
      setConfig(configRes.data);
      setQuoNo(configRes.data.quotationId);
    }

    const taskRes = await fetchWithGet('/tasks?serviceFee=false');
    let tasks = [];
    if (taskRes.status === 200) {
      tasks = [
        ...taskRes.data,
        {
          label: 'Other',
          value: '',
          name: '',
          price: 0,
          qty: 0,
          serviceFee: false,
          description: '',
          remark: '',
        },
      ];
      setTaskList(tasks);
    }

    const userRes = await fetchWithGet('/users', { active: true, deleted: false, sortBy: 'email:asc' });
    let filteredUser = [];
    if (userRes.status === 200) {
      filteredUser = userRes.data
        .filter((e) => e.role !== 'vendor' && e.role !== 'admin')
        .map((e) => {
          return { label: `${e.name} - ${e.email}`, value: e.id };
        });
      setUsers(filteredUser);
    }

    if (id) {
      const result = await fetchWithGet('/projects/getProjectDetail', { projectId: id });
      if (result.status === 200) {
        setQuoNo(result.data.quoNo);
        setData({
          ...result.data,
          quoDate: Date.parse(result.data.quoDate),
          estEndDate: Date.parse(result.data.estEndDate),
          type: _.find(types, (e) => e.value === result.data.type),
          preparedBy: _.find(filteredUser, (e) => e.value === result.data.userId.id),
        });
        setProjectDetails(
          result.data.details.map((e) => {
            return {
              ...e,
              taskSelected: _.find(tasks, (a) => a.label === e.name) || { label: 'Other', value: '' },
            };
          })
        );
      }
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const _createQuotation = async (e) => {
    e.preventDefault();
    if (!data.preparedBy) {
      alertContext.setAlert('Please select prepared by', 'danger');
      return;
    }
    if (!data.quoDate) {
      alertContext.setAlert('Quotation Date cannot be null', 'danger');
      return;
    }
    if (!data.estEndDate) {
      alertContext.setAlert('Due Date cannot be null', 'danger');
      return;
    }
    if (!data.name.en && !data.name.zh) {
      alertContext.setAlert('Company/Customer name cannot be null', 'danger');
      return;
    }
    if (!projectDetails || projectDetails.length === 0) {
      alertContext.setAlert('Please add project details', 'danger');
      return;
    }
    if (projectDetails.map((v) => !!v.name && !!v.price && !!v.qty).includes(false)) {
      alertContext.setAlert('Please complete project details', 'danger');
      return;
    }

    const result = await fetchWithPost('/projects/createProject', {
      ...data,
      ...{
        type: data.type.value,
        preparedBy: data.preparedBy.value,
        totalAmount: _.sumBy(projectDetails, (v) => v.qty * v.price),
        details: projectDetails.map((v) => ({
          name: v.name,
          serviceFee: v.serviceFee,
          price: v.price,
          qty: v.qty,
          remark: v.remark,
          description: v.description,
        })),
      },
    });
    if (result.status === 200) {
      alertContext.setAlert('Successfully created', 'success');
      fetchApi();
      window.location.href = '/project/list';
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };
  const _editQuotation = async (e) => {
    e.preventDefault();
    if (!data.preparedBy) {
      alertContext.setAlert('Please select prepared for', 'danger');
      return;
    }
    if (!data.quoDate) {
      alertContext.setAlert('Quotation Date cannot be null', 'danger');
      return;
    }
    if (!data.estEndDate) {
      alertContext.setAlert('Due Date cannot be null', 'danger');
      return;
    }
    if (!data.name) {
      alertContext.setAlert('Customer name cannot be null', 'danger');
      return;
    }
    if (!projectDetails || projectDetails.length === 0) {
      alertContext.setAlert('Please add project details', 'danger');
      return;
    }
    if (projectDetails.map((v) => !!v.name && !!v.price && !!v.qty).includes(false)) {
      alertContext.setAlert('Please complete project details', 'danger');
      return;
    }
    const result = await fetchWithPost('/projects/updateProject', {
      ..._.pick(data, [
        'id',
        'name',
        'estEndDate',
        'contactPerson',
        'phoneNo',
        'address',
        'email',
        'remark',
        'quoDate',
        'preparedBy',
      ]),
      ...{
        type: data.type.value,
        preparedBy: data.preparedBy.value,
        totalAmount: _.sumBy(projectDetails, (v) => v.qty * v.price),
        details: projectDetails.map((v) => ({
          name: v.name,
          serviceFee: v.serviceFee,
          price: v.price,
          qty: v.qty,
          remark: v.remark,
          description: v.description,
        })),
      },
    });
    if (result.status === 200) {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
      history.push(`/project/list/${data.id}`);
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
        {id ? (
          <>
            <BreadcrumbItem>
              <Link to={`${rootPath}/${id}`}>{!_.isEmpty(data) ? data.invNo || data.quoNo || data.enqNo : id}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>{'Edit'}</BreadcrumbItem>
          </>
        ) : (
          <></>
        )}
      </Breadcrumb>

      <Row>
        <Col>
          <Card>
            <CardHeader>Project Info.</CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="quoNo">
                    Quotation No. <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input type="text" name="quoNo" id="quoNo" placeholder="Quotation No." value={quoNo} disabled />
                </FormGroup>
                <FormGroup>
                  <Label for="quoDate">
                    Quotation Date <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <DatePicker
                    closeOnScroll={true}
                    name="quoDate"
                    id="quoDate"
                    className="form-control d-block"
                    selected={data.quoDate}
                    onChange={(date) =>
                      setData({
                        ...data,
                        quoDate: date,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="estEndDate">
                    Due Date <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <DatePicker
                    closeOnScroll={true}
                    name="estEndDate"
                    id="estEndDate"
                    className={'form-control'}
                    selected={data.estEndDate}
                    onChange={(date) =>
                      setData({
                        ...data,
                        estEndDate: date,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="notes">Remark</Label>
                  <textarea
                    style={{ resize: 'none' }}
                    className={'form-control'}
                    type="text"
                    name="notes"
                    id="notes"
                    placeholder="Remark"
                    rows="4"
                    cols="50"
                    value={data.remark}
                    onChange={(e) => {
                      setData({
                        ...data,
                        remark: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="preparedBy">
                    Prepared for <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Select
                    options={users}
                    value={data.preparedBy}
                    isSearchable={false}
                    onChange={(e) => {
                      setData({
                        ...data,
                        preparedBy: e,
                      });
                    }}
                  />
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>Customer Info.</CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="customer">
                    Company/Customer <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Select
                    options={types}
                    value={data.type}
                    isSearchable={false}
                    onChange={(e) =>
                      setData({
                        ...data,
                        type: e,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="customer">
                    {_.startCase(data.type.value)} Name (English)<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={`${_.startCase(data.type.value)} Name (English)`}
                    value={data.name.en}
                    onChange={(e) =>
                      setData({
                        ...data,
                        name: {
                          ...data.name,
                          en: e.target.value,
                        },
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="customer"> {_.startCase(data.type.value)} Name (Chinese)</Label>
                  <Input
                    type="text"
                    name="name_zh"
                    id="name_zh"
                    placeholder={`${_.startCase(data.type.value)} Name (Chinese)`}
                    value={data.name.zh}
                    onChange={(e) =>
                      setData({
                        ...data,
                        name: {
                          ...data.name,
                          zh: e.target.value,
                        },
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="contactPerson">Contact Person</Label>
                  <Input
                    type="text"
                    name="contactPerson"
                    id="contactPerson"
                    placeholder="Contact Person"
                    value={data.contactPerson}
                    onChange={(e) =>
                      setData({
                        ...data,
                        contactPerson: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phoneNo">Phone No.</Label>
                  <Input
                    type="number"
                    name="phoneNo"
                    id="phoneNo"
                    placeholder="Phone No."
                    value={data.phoneNo}
                    onChange={(e) =>
                      setData({
                        ...data,
                        phoneNo: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={data.email}
                    onChange={(e) =>
                      setData({
                        ...data,
                        email: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={data.address}
                    onChange={(e) =>
                      setData({
                        ...data,
                        address: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col>Project Details</Col>
                <Col className="text-right">
                  <Button
                    onClick={() =>
                      setProjectDetails([
                        ...projectDetails,
                        {
                          id: moment().unix() + projectDetails.length,
                          taskSelected: '',
                          name: '',
                          description: '',
                          qty: 0,
                          price: 0,
                          remark: '',
                        },
                      ])
                    }
                  >
                    <span>
                      <FA name="plus" />
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="table-responsive m-b">
                <table className="table m-t-50">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Description</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectDetails.map((v, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <Form>
                            <FormGroup>
                              <Select
                                options={taskList}
                                isSearchable={false}
                                placeholder="Name"
                                value={v.taskSelected}
                                onChange={(e) => {
                                  const updateObj = update(projectDetails, {
                                    [i]: {
                                      taskSelected: { $set: e },
                                      name: { $set: e.name || '' },
                                      price: { $set: e.price },
                                      qty: { $set: e.qty },
                                      serviceFee: { $set: e.serviceFee },
                                      description: { $set: e.description || '' },
                                      remark: { $set: e.remark || '' },
                                    },
                                  });

                                  setProjectDetails(updateObj);
                                }}
                              />
                            </FormGroup>

                            {projectDetails[i].taskSelected.value === '' ? (
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="detailName"
                                  id="detailName"
                                  placeholder="Name"
                                  value={v.name}
                                  onChange={(e) => {
                                    const updateObj = update(projectDetails, {
                                      [i]: {
                                        name: { $set: e.target.value },
                                      },
                                    });

                                    setProjectDetails(updateObj);
                                  }}
                                />
                              </FormGroup>
                            ) : null}

                            <FormGroup>
                              <textarea
                                style={{ resize: 'none' }}
                                className={'form-control'}
                                type="text"
                                name="description"
                                id="description"
                                placeholder="Description"
                                rows="4"
                                cols="50"
                                value={v.description}
                                onChange={(e) => {
                                  const updateObj = update(projectDetails, {
                                    [i]: {
                                      description: { $set: e.target.value },
                                    },
                                  });

                                  setProjectDetails(updateObj);
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                type="text"
                                name="remark"
                                id="remark"
                                placeholder="Remark"
                                value={v.remark}
                                onChange={(e) => {
                                  const updateObj = update(projectDetails, {
                                    [i]: {
                                      remark: { $set: e.target.value },
                                    },
                                  });

                                  setProjectDetails(updateObj);
                                }}
                              />
                            </FormGroup>
                          </Form>
                        </td>
                        <td>
                          <Form>
                            <FormGroup>
                              <Input
                                type="number"
                                name="qty"
                                id="qty"
                                placeholder="Qty"
                                value={v.qty}
                                onChange={(e) => {
                                  const updateObj = update(projectDetails, {
                                    [i]: {
                                      qty: { $set: e.target.value },
                                    },
                                  });

                                  setProjectDetails(updateObj);
                                }}
                              />
                            </FormGroup>
                          </Form>
                        </td>
                        <td>
                          <Form>
                            <FormGroup>
                              <Input
                                type="number"
                                name="price"
                                id="price"
                                placeholder="Price"
                                value={v.price}
                                onChange={(e) => {
                                  const updateObj = update(projectDetails, {
                                    [i]: {
                                      price: { $set: e.target.value },
                                    },
                                  });

                                  setProjectDetails(updateObj);
                                }}
                              />
                            </FormGroup>
                          </Form>
                        </td>
                        <td>
                          <FormGroup>
                            <Button
                              color="danger"
                              className="rounded-circle"
                              onClick={() => {
                                setProjectDetails(_.remove(projectDetails, (e) => e.id !== v.id));
                              }}
                            >
                              <FA name="minus" />
                            </Button>
                          </FormGroup>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={3} className={'text-right'}>
                        Total Amount (HKD$)
                      </td>
                      <td colSpan={2}>{_.sumBy(projectDetails, (v) => v.qty * v.price)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {id ? (
        <Button
          style={{ position: 'fixed', left: 0, bottom: 0 }}
          color="primary"
          block
          onClick={(e) => _editQuotation(e)}
        >
          Edit
        </Button>
      ) : (
        <Button
          style={{ position: 'fixed', left: 0, bottom: 0 }}
          color="primary"
          block
          onClick={(e) => _createQuotation(e)}
        >
          Create
        </Button>
      )}
    </>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  Row,
  Col,
  Label,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { Loader } from '../../components';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import _ from 'lodash';

export default function Payslip() {
  let { path, url } = useRouteMatch();
  const rootName = 'Payslip List';
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <PayslipList rootPath={url} rootName={rootName} />
        </Route>
        <Route exact path={`${path}/vendor`}>
          <PayslipListForVendor rootPath={url} rootName={rootName} />
        </Route>
        <Route path={`${path}/:id`}>
          <PayslipDetail rootPath={url} rootName={rootName} />
        </Route>
      </Switch>
    </Router>
  );
}

function PayslipList({ rootPath, rootName }) {
  const history = useHistory();
  const [payslips, setPayslips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //context
  const alertContext = useContext(PageAlertContext);

  const [data, setData] = useState([]);
  const columns = [
    '#',
    'Name',
    'Period From',
    'Period To',
    'Released',
    // {
    //   name: 'Show Payslip',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => (
    //       <Input
    //         style={{ position: 'static', marginTop: 0, marginLeft: 0 }}
    //         type="checkbox"
    //         checked={payslips[tableMeta.rowIndex].display}
    //         onClick={(v) => updatePayslip(v, tableMeta.rowIndex, payslips, v.target.checked)}
    //       />

    //       // <Button onClick={() => downloadPaylsip(tableMeta.rowIndex, payslips)} color="primary" outline>Download</Button>
    //     ),
    //   },
    // },
  ];
  const options = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${payslips[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/payslip');
    if (result) {
      setPayslips(result.data);
      setData(
        result.data.map((v, i) => [
          i + 1,
          v.name,
          v.startDate && moment(v.startDate).format('YYYY-MM-DD'),
          v.endDate && moment(v.endDate).format('YYYY-MM-DD'),
          v.display ? 'True' : 'False',
        ])
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const newPayslip = () => {
    history.push(`${rootPath}/New`);
  };

  const updatePayslip = async (event, index, payslips, display) => {
    event.stopPropagation();
    const result = await fetchWithPost('/payslip/updatePayslip', { payslipId: payslips[index].id, display });
    if (result.status === 200 || result.status === 201) {
      payslips[index].display = display;
      await setPayslips(payslips);
      alertContext.setAlert('Successfully updated', 'success');
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Button onClick={() => newPayslip()} color="primary" className={'mb-2'}>
        New
      </Button>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function PayslipListForVendor({ rootPath, rootName }) {
  const [payslips, setPayslips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const columns = [
    '#',
    'Payslip',
    'Period From',
    'Period To',
    'Total Amt.',
    {
      name: 'Download',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Button onClick={() => downloadPaylsip(tableMeta.rowIndex, payslips)} color="primary">
            Download
          </Button>
        ),
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/payslip/getPayslipDetailList');
    const { data } = result;
    if (result) {
      setPayslips(data);
      setData(
        data.map((v, i) => [
          i + 1,
          v.payslipId.name,
          moment(v.dateFrom).format('YYYY-MM-DD'),
          moment(v.dateTo).format('YYYY-MM-DD'),
          v.subTotal,
        ])
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function PayslipDetail({ rootPath, rootName }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();

  //context
  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [recordName, setRecordName] = useState('');
  const [payslipDetailId, setPayslipDetailId] = useState('');
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm')));
  const [endDate, setEndDate] = useState(new Date(moment().endOf('month').format('YYYY-MM-DD hh:mm')));
  const [display, setDisplay] = useState(false);
  const [payslips, setPayslips] = useState([]);
  const [data, setData] = useState([]);
  const columns = [
    '#',
    'Staff Name',
    'Company Name',
    'Role',
    'Total Amt.',
    {
      name: 'Download',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Button onClick={() => downloadPaylsip(tableMeta.rowIndex, payslips)} color="primary">
            Download
          </Button>
        ),
      },
    },
    {
      name: 'Upload Record',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) =>
          payslips[tableMeta.rowIndex].documents && payslips[tableMeta.rowIndex].documents.length ? (
            <a href={payslips[tableMeta.rowIndex].documents[0].documentPath} target="_blank">
              {payslips[tableMeta.rowIndex].documents[0].documentName}
            </a>
          ) : (
            <Button
              onClick={() => {
                setModal(!modal);
                setPayslipDetailId(payslips[tableMeta.rowIndex].id);
              }}
              color="danger"
            >
              Upload Record
            </Button>
          ),
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    print: false,
    textLabels: {
      body: {
        noMatch: 'Sorry, no matching records found',
      },
    },
  };

  const fetchApi = async () => {
    const result = await fetchWithGet('/payslip/getPayslipById', { payslipId: id });
    if (result.status === 200) {
      const { name, startDate, endDate, payslips, display } = result.data;
      setName(name);
      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
      setPayslips(payslips);
      setDisplay(display);
      setData(payslips.map((v, i) => [i + 1, v.staffName, v.companyName, _.startCase(v.role), v.subTotal]));
    }
  };
  useEffect(() => {
    if (id !== 'New') fetchApi();
  }, []);

  const updatePayslip = async (display) => {
    const result = await popUpContext.onOpen(
      'Are you confirm to release payslip? (Once released, you will not be able to revert it)'
    );
    if (result) {
      const result = await fetchWithPost('/payslip/updatePayslip', { payslipId: id, display });
      if (result.status === 200 || result.status === 201) {
        alertContext.setAlert('Successfully updated', 'success');
        setDisplay(display);
      } else {
        alertContext.setAlert(result.data.message, 'danger');
      }
    }
  };

  const deletePayslip = async () => {
    const result = await popUpContext.onOpen('Are you confirm to delete?');
    if (result) {
      const result = await fetchWithPost('/payslip/deletePayslip', { payslipId: id });
      if (result.status === 200 || result.status === 201) {
        alertContext.setAlert('Successfully deleted', 'success');
        history.push(rootPath);
      } else {
        alertContext.setAlert(result.data.message, 'danger');
      }
    }
  };

  const issuePayslip = async () => {
    const result = await popUpContext.onOpen('Are you confirm to generate payslip?');
    if (result) {
      setIsLoading(true);
      const result = await fetchWithPost('/payslip/issuePayslip', {
        name,
        startDate,
        endDate,
      });
      if (result.status === 200 || result.status === 201) {
        const { name, startDate, endDate, payslips } = result.data;
        setName(name);
        setStartDate(new Date(startDate));
        setEndDate(new Date(endDate));
        setPayslips(payslips);
        setData(payslips.map((v, i) => [i + 1, v.staffName, v.companyName, v.role, v.subTotal]));
        id = result.data.id;
        alertContext.setAlert('Successfully generated', 'success');

        history.push(`${rootPath}/${result.data.id}`);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        alertContext.setAlert(result.data.message, 'danger');
      }
    }
  };

  const uploadRecrod = async () => {
    setIsLoading(true);
    const file = document.getElementById('record');
    const result = await fetchWithFormData('/payslip/uploadDocument', {
      file: file.files[0],
      documentName: recordName,
      payslipDetailId: payslipDetailId,
    });
    if (result.status === 200 || result.status === 201) {
      const { name, startDate, endDate, payslips } = result.data;
      setName(name);
      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
      setPayslips(payslips);
      setData(payslips.map((v, i) => [i + 1, v.staffName, v.companyName, v.role, v.subTotal]));
      id = result.data.id;
      alertContext.setAlert('Successfully generated', 'success');
      setRecordName('');
      setModal(!modal);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alertContext.setAlert(result.data.message, 'danger');
    }
  };

  return isLoading ? (
    <Loader type={'spin'} />
  ) : (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/ ${id === 'New' ? 'New' : id}`}>{id === 'New' ? 'New Payslip' : name}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Modal centered={true} isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader
          toggle={() => {
            setModal(!modal);
            setRecordName('');
          }}
        >
          <b>Upload Record</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Document Name</Label>
            <Input placeholder="Document Name" value={recordName} onChange={(v) => setRecordName(v.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label for="exampleFile">File</Label>
            <Input type="file" name="record" id="record" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              uploadRecrod();
            }}
          >
            Upload
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col md={{ size: 12 }}>
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="name">Payslip Name</Label>
                  <Input
                    disabled={payslips && payslips.length}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Payslip Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for="name">Period From</Label>
                  <DatePicker
                    disabled={payslips && payslips.length}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="name">Period To</Label>
                  <DatePicker
                    disabled={payslips && payslips.length}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </FormGroup> */}
                {payslips && payslips.length ? (
                  <FormGroup>
                    <Label for="active">Release Payslip</Label> &nbsp;
                    <Input
                      type="checkbox"
                      checked={display}
                      onChange={(v) => updatePayslip(v.target.checked)}
                      disabled={display}
                    />
                    <Label style={{ color: 'red' }} for="active">
                      (Users can view it or not)
                    </Label>
                  </FormGroup>
                ) : null}
                {/* <Button onClick={() => history.push(rootPath)} color="primary">
                  Back
                </Button> */}
                {id !== 'New' ? (
                  <Button onClick={deletePayslip} color="danger" disabled={display}>
                    Remove
                  </Button>
                ) : (
                  <></>
                )}
                &nbsp;
                {payslips && !payslips.length ? (
                  <Button onClick={() => issuePayslip()} color="success">
                    Generate Payslip
                  </Button>
                ) : null}
              </Form>
              {payslips && payslips.length ? (
                <div style={{ paddingTop: '10px' }}>
                  <Label for="name">Payslips</Label>
                  <MUIDataTable data={data} columns={columns} options={options} />
                </div>
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const downloadPaylsip = async (index, payslips) => {
  const result = await fetchWithGet('/payslip/generatePayslip', { payslipDetailId: payslips[index].id });
  const {
    data: { staffName, startDate, data },
  } = result;
  const linkSource = `data:application/pdf;base64,${data}`;
  const downloadLink = document.createElement('a');
  const fileName = `${staffName}_${moment(startDate).format('YYYYMMDD')}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

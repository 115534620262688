import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  Row,
  Col,
  Label,
  Form,
  InputGroup,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchWithGet, fetchWithPost, fetchWithDelete } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { Loader } from '../../components';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import CreatableSelect from 'react-select/creatable';

export default function UserListPage() {
  let { path, url } = useRouteMatch();
  const rootName = 'User List';
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <UserList rootPath={url} rootName={rootName} />
        </Route>
        <Route path={`${path}/:id`}>
          <UserDetail rootPath={url} rootName={rootName} />
        </Route>
      </Switch>
    </Router>
  );
}

function UserList({ rootPath, rootName }) {
  //context
  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [adminGroupList, setAdminGroupList] = useState([{ value: 'none', label: 'None' }]);
  const [selectAdminGroup, setSelectAdminGrooup] = useState(adminGroupList[0].value);

  const [data, setData] = useState([]);
  const columns = ['#', 'Name', 'Role', 'Company Name', 'Status', 'Email', 'Phone No', 'Created At'];
  const options = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${users[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/users', { deleted: false, sortBy: 'email:asc' });
    if (result) {
      const filtered = result.data.filter((e) => e.type !== 'root');
      setUsers(filtered);
      setData(
        filtered.map((v, i) => [
          i + 1,
          v.name,
          _.startCase(v.role),
          v.companyName,
          v.active ? 'Active' : 'Inactive',
          v.email,
          v.tel,
          v.createdDatetime && moment(v.createdDatetime).format('YYYY-MM-DD'),
        ])
      );
    }
    setIsLoading(false);

    const adminGroupListRes = await fetchWithGet('/cms/adminGroup');
    if (adminGroupListRes) {
      setAdminGroupList([
        { value: 'none', label: 'None' },
        ...adminGroupListRes.data.map((e) => {
          return { ...e, label: e.groupName, value: e.id };
        }),
      ]);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const newUser = () => {
    history.push(`${rootPath}/New`);
  };

  const _removeAdminGroup = async () => {
    if (selectAdminGroup !== 'none') {
      const result = await popUpContext.onOpen('Are you confirm to remove this admin group?');
      if (result) {
        const adminGroupListRes = await fetchWithDelete('/cms/adminGroup', { id: selectAdminGroup });
        if (adminGroupListRes.status === 200) {
          const newList = adminGroupListRes.data.map((e) => {
            return { ...e, label: e.groupName, value: e.id };
          });
          setAdminGroupList([{ value: 'none', label: 'None' }, ...newList]);
          alertContext.setAlert('Successfully removed', 'success');
        } else {
          alertContext.setAlert(result.data.message, 'danger');
        }
        fetchApi();
      }
    } else {
      alertContext.setAlert('Please select admin group', 'danger');
    }
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Row className={'mb-2'}>
        <Col>
          <Button onClick={() => newUser()} color="primary">
            Create new user
          </Button>
        </Col>
        <Col>
          <InputGroup>
            <Input type="select" value={selectAdminGroup} onChange={(v) => setSelectAdminGrooup(v.target.value)}>
              {adminGroupList.map((group) => {
                return <option value={group.value}>{group.label}</option>;
              })}
            </Input>
            <Button onClick={() => _removeAdminGroup()} color="danger">
              Remove admin group
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function UserDetail({ rootPath, rootName }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();

  //context
  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(null);
  const [tel, setTel] = useState('');
  const [address1, setAddress1] = useState('');
  const [bankHolderName, setBankHolderName] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [parentId, setParentId] = useState(null);
  const [role, setRole] = useState('broker');
  const [active, setActive] = useState(true);
  const [userAdminGroup, setUserAdminGroup] = useState([]);

  const [brokerList, setBrokerList] = useState([{ id: 'none', name: 'None' }]);
  const [adminGroupList, setAdminGroupList] = useState([]);

  const fetchApi = async () => {
    if (id !== 'New') {
      const result = await fetchWithGet(`/users/${id}`);
      if (result.status === 200) {
        const {
          name,
          email,
          tel,
          address1,
          bankHolderName,
          bankName,
          bankAccount,
          paymentMethod,
          companyName,
          role,
          active,
          parentId,
          adminGroup,
        } = result.data;
        setName(name);
        setEmail(email);
        setTel(tel);
        setAddress1(address1);
        setBankHolderName(bankHolderName);
        setBankName(bankName);
        setBankAccount(bankAccount);
        setPaymentMethod(paymentMethod);
        setCompanyName(companyName);
        setRole(role);
        setActive(active);
        setUserAdminGroup(
          (adminGroup || []).map((e) => {
            return { ...e, label: e.groupName, value: e.id };
          })
        );
        const brokerListRes = await fetchWithGet('/users', {
          role: 'broker',
          sortBy: 'email:asc',
          active: true,
          deleted: false,
        });
        if (brokerListRes) {
          const parent = brokerListRes.data.find((broker) => {
            return broker.id === parentId;
          });
          const list = brokerListRes.data.filter((broker) => {
            return broker.id !== id;
          });
          setBrokerList([{ id: 'none', name: 'None' }, ...list]);
          if (parent) setParentId(parentId);
        }
      }
    } else {
      const brokerListRes = await fetchWithGet('/users', {
        role: 'broker',
        sortBy: 'email:asc',
        active: true,
        deleted: false,
      });
      if (brokerListRes) {
        setBrokerList([{ id: 'none', name: 'None' }, ...brokerListRes.data]);
      }
    }
    const adminGroupListRes = await fetchWithGet('/cms/adminGroup');
    if (adminGroupListRes) {
      setAdminGroupList(
        adminGroupListRes.data.map((e) => {
          return { ...e, label: e.groupName, value: e.id };
        })
      );
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const _createAdminGroup = async (groupName) => {
    const adminGroupListRes = await fetchWithPost('/cms/adminGroup', { groupName });
    if (adminGroupListRes) {
      const newList = adminGroupListRes.data.map((e) => {
        return { ...e, label: e.groupName, value: e.id };
      });
      setAdminGroupList(newList);
      setUserAdminGroup([...userAdminGroup, _.find(newList, ['label', groupName])]);
    }
    fetchApi();
  };

  const saveUser = async () => {
    if (role === 'sales' && !(parentId && parentId !== 'none')) {
      alertContext.setAlert('Sales must under one broker', 'danger');
      return;
    }
    const body = {
      name,
      email,
      tel,
      address1,
      companyName,
      bankHolderName,
      bankName,
      bankAccount,
      paymentMethod: paymentMethod || '',
      role,
      active,
      parentId: !(parentId && parentId !== 'none') ? null : parentId,
      adminGroup: (userAdminGroup || []).map((e) => e.value),
    };

    if (id === 'New' || password) Object.assign(body, { password });

    const result = await fetchWithPost(id === 'New' ? '/users' : `/users/${id}`, body);
    if (result.status === 200 || result.status === 201) {
      alertContext.setAlert('Successfully updated', 'success');
      history.push(rootPath);
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };

  const deleteUser = async () => {
    const result = await popUpContext.onOpen('Are you confirm to delete?');
    if (result) {
      const result = await fetchWithPost(`/users/${id}`, { deleted: true });
      if (result.status === 200 || result.status === 201) {
        alertContext.setAlert('Password has been changed', 'success');
        history.push(rootPath);
      } else {
        alertContext.setAlert(result.data.message, 'danger');
      }
    }
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/ ${id === 'New' ? 'New' : id}`}>{id === 'New' ? 'New User' : name}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col>
          <Label>User Info.</Label>
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="name">
                    Name <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">
                    Email <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">
                    Password{' '}
                    {id !== 'New' ? (
                      <Label style={{ color: 'red' }}>(For change password)</Label>
                    ) : (
                      <span style={{ color: 'red' }}>*</span>
                    )}
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="tel">Phone No.</Label>
                  <Input
                    type="text"
                    name="tel"
                    id="tel"
                    placeholder="Phone No."
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="address1">Address</Label>
                  <Input
                    type="text"
                    name="address1"
                    id="address1"
                    placeholder="Address"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="companyName">Comapny Name</Label>
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </FormGroup>
                {/* &nbsp;
                    {id !== 'New' ? (
                      <Button onClick={() => deleteUser()} color="danger" outline>
                        Delete
                      </Button>
                    ) : null}
                    &nbsp; */}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>Bank & Payment Info.</Label>
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="bankHolderName">Bank Holder Name</Label>
                  <Input
                    type="text"
                    name="bankHolderName"
                    id="bankHolderName"
                    placeholder="Bank Holder Name"
                    value={bankHolderName}
                    onChange={(e) => setBankHolderName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="bankName">Bank Name</Label>
                  <Input
                    type="text"
                    name="bankName"
                    id="bankName"
                    placeholder="Bank Name"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="bankAccount">Bank Account</Label>
                  <Input
                    type="text"
                    name="bankAccount"
                    id="bankAccount"
                    placeholder="Bank Account"
                    value={bankAccount}
                    onChange={(e) => setBankAccount(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="paymentMethod">Payment Method</Label>
                  <Input
                    type="select"
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={(v) => setPaymentMethod(v.target.value)}
                  >
                    <option value={''}>None</option>
                    <option value={'autopay'}>Autopay</option>
                    <option value={'cash'}>Cash</option>
                    <option value={'cheque'}>Cheque</option>
                  </Input>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <Button style={{ position: 'fixed', left: 0, bottom: 0 }} color="primary" block onClick={() => saveUser()}>
            Save
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>User Setting</Label>
          <Card>
            <CardBody>
              <FormGroup>
                <Label for="role">Role</Label>
                <Input
                  type="select"
                  name="role"
                  value={role}
                  onChange={(v) => {
                    setRole(v.target.value);
                    setParentId(null);
                  }}
                >
                  <option value={'broker'}>Broker</option>
                  <option value={'senior_sales'}>Senior Sales</option>
                  <option value={'sales'}>Sales</option>
                  <option value={'admin'}>Admin</option>
                  <option value={'vendor'}>Vendor</option>
                </Input>
              </FormGroup>
              <Form>
                {role === 'vendor' || role === 'admin' ? (
                  <></>
                ) : (
                  <FormGroup>
                    <Label for="parent">Parent</Label>
                    <Input type="select" name="parent" value={parentId} onChange={(v) => setParentId(v.target.value)}>
                      {brokerList.map((broker) => {
                        return (
                          <option value={broker.id}>
                            {broker.name} {broker.id !== 'none' ? `(${broker.email})` : null}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                )}
                {role === 'admin' ? (
                  <FormGroup>
                    <Label>Admin Group</Label>
                    <CreatableSelect
                      isMulti
                      options={adminGroupList}
                      value={userAdminGroup}
                      onChange={(e) => {
                        setUserAdminGroup(e);
                      }}
                      onCreateOption={(e) => _createAdminGroup(e)}
                    />
                  </FormGroup>
                ) : (
                  <></>
                )}
                <FormGroup>
                  <Label for="active">Status</Label>
                  <Input type="select" name="active" value={active} onChange={(v) => setActive(v.target.value)}>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Input>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <Button style={{ position: 'fixed', left: 0, bottom: 0 }} color="primary" block onClick={() => saveUser()}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
}

import axios from 'axios';
import Cookies from 'js-cookie';

// const _api = 'http://localhost:3000/v1/';
const _api = 'https://admin.stepfirst.com.hk/api/v1';

const _fetchWithToken = (req, token, contentType) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': contentType || 'application/json',
  };
  const baseURL = _api;

  console.log('Token', { ...req, headers, baseURL });

  return axios({ ...req, headers, baseURL });
};

const handleError = (err, language, request, context) => {
  // do error action here
  console.log('Error request', request, err);
  const { status } = err.response;
  if (status === 401 || status === 403) {
    console.log('Unauthorized');
    Cookies.remove('token');
    window.location.reload();
  } else {
    return err.response;
  }
};

export const fetchWithGet = async (path, data) => {
  const token = Cookies.get('token');
  const req = {
    method: 'GET',
    url: path,
    params: data,
  };

  console.log('Get fetch:', req);

  try {
    const r0 = await _fetchWithToken(req, token);
    return r0;
  } catch (err) {
    return handleError(err, 'en', req);
  }
};

export const fetchWithPost = async (path, data) => {
  const token = Cookies.get('token');
  const req = {
    method: 'POST',
    url: path,
    data,
  };

  console.log('Post fetch:', req);

  try {
    const r0 = await _fetchWithToken(req, token);
    return r0;
  } catch (err) {
    return handleError(err, 'en', req);
  }
};

export const fetchWithFormData = async (path, data) => {
  const token = Cookies.get('token');
  const formData = new FormData();
  for (const key in data) {
    if (data[key]) formData.append(key, data[key]);
  }

  const req = {
    method: 'POST',
    url: path,
    data: formData,
  };

  console.log('Post fetch:', req);

  try {
    const r0 = await _fetchWithToken(req, token, 'multipart/form-data');
    return r0;
  } catch (err) {
    return handleError(err, 'en', req);
  }
};

export const fetchWithDelete = async (path, data) => {
  const token = Cookies.get('token');
  const req = {
    method: 'DELETE',
    url: path,
    params: data,
  };

  console.log('Delete fetch:', req);

  try {
    const r0 = await _fetchWithToken(req, token);
    return r0;
  } catch (err) {
    return handleError(err, 'en', req);
  }
};

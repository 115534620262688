import React, { useState, useEffect, useContext } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';

export default function BasicConfigPage() {
  let { path, url } = useRouteMatch();
  const rootName = 'Basic Config';
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <BasicConfig rootPath={url} rootName={rootName} />
        </Route>
      </Switch>
    </Router>
  );
}

function BasicConfig({ rootPath, rootName }) {
  //context
  const alertContext = useContext(PageAlertContext);

  const [companyInfo, setCompanyInfo] = useState({});

  const fetchApi = async () => {
    const result = await fetchWithGet('/cms/basicConfig');
    if (result) {
      setCompanyInfo(result.data);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const _updateConfig = async () => {
    delete companyInfo.id;
    delete companyInfo.createdDatetime;
    const result = await fetchWithPost('/cms/basicConfig', companyInfo);

    if (result.status === 200) {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardHeader>Company Name</CardHeader>
        <CardBody>
          <Input
            type="textarea"
            style={{ height: 100 }}
            placeholder="Company Name"
            value={companyInfo.companyName}
            onChange={(v) => setCompanyInfo({ ...companyInfo, companyName: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Company Address</CardHeader>
        <CardBody>
          <Input
            type="textarea"
            style={{ height: 100 }}
            placeholder="Company Address"
            value={companyInfo.companyAddress}
            onChange={(v) => setCompanyInfo({ ...companyInfo, companyAddress: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Company Contact No</CardHeader>
        <CardBody>
          <Input
            placeholder="Company Contact No"
            value={companyInfo.companyContactNo}
            onChange={(v) => setCompanyInfo({ ...companyInfo, companyContactNo: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Inquiry Information</CardHeader>
        <CardBody>
          <Input
            type="textarea"
            style={{ height: 100 }}
            placeholder="Inquiry Information"
            value={companyInfo.inquiryInfo}
            onChange={(v) => setCompanyInfo({ ...companyInfo, inquiryInfo: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Bank Information</CardHeader>
        <CardBody>
          <Input
            type="textarea"
            style={{ height: 100 }}
            placeholder="Bank Information"
            value={companyInfo.bankInfo}
            onChange={(v) => setCompanyInfo({ ...companyInfo, bankInfo: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Payment Method</CardHeader>
        <CardBody>
          <Input
            type="textarea"
            style={{ height: 100 }}
            placeholder="Payment Method"
            value={companyInfo.paymentMethod}
            onChange={(v) => setCompanyInfo({ ...companyInfo, paymentMethod: v.target.value })}
          />
        </CardBody>
      </Card>
      <Button style={{ position: 'fixed', left: 0, bottom: 0 }} color="primary" block onClick={() => _updateConfig()}>
        Save
      </Button>
    </>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import { Loader } from '../../components';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';

export default function ServiceConfigPage() {
  let { path, url } = useRouteMatch();
  const rootName = 'Service Config';
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <TaskList rootPath={url} rootName={rootName} />
        </Route>
        <Route path={`${path}/create`}>
          <TaskDetail rootPath={url} rootName={rootName} />
        </Route>
        <Route path={`${path}/:id`}>
          <TaskDetail rootPath={url} rootName={rootName} />
        </Route>
      </Switch>
    </Router>
  );
}

function TaskList({ rootPath, rootName }) {
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const columns = ['#', 'Service Name', 'Description', 'Price', 'Direct Cost', 'Qty'];
  const options = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${tasks[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/tasks');
    console.log(result);
    if (result) {
      setTasks(result.data);
      setData(
        result.data.map((v, i) => [
          i + 1,
          v.name || '',
          v.description || '',
          v.price || '',
          v.serviceFee ? 'True' : 'False',
          v.qty,
        ])
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Button onClick={() => history.push(`${rootPath}/create`)} color="primary" className={'mb-2'}>
        Create new service
      </Button>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function TaskDetail({ rootPath, rootName }) {
  let { id } = useParams();
  let { url } = useRouteMatch();

  //context
  const alertContext = useContext(PageAlertContext);

  //data
  const [taskDetail, setTaskDetail] = useState({
    name: '',
    description: '',
    qty: '',
    price: '',
    serviceFee: false,
  });

  //inputs
  const fetchApi = async () => {
    console.log({ id });
    if (id) {
      const result = await fetchWithGet('/tasks');
      if (result) {
        const grpBy = _.groupBy(result.data, 'id');
        setTaskDetail(grpBy[id][0]);
      }
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const _updateConfig = async () => {
    delete taskDetail.createdDatetime;
    delete taskDetail.value;
    delete taskDetail.label;
    const result = await fetchWithPost('/tasks', taskDetail);
    if (result.status === 200 || result.status === 201) {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${id}`}>{id ? (!_.isEmpty(taskDetail) ? taskDetail.name : id) : 'Create'}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardHeader>Service Name</CardHeader>
        <CardBody>
          <Input
            placeholder="Service Name"
            value={taskDetail.name}
            onChange={(v) => setTaskDetail({ ...taskDetail, name: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Description</CardHeader>
        <CardBody>
          <Input
            type="textarea"
            style={{ height: 100 }}
            placeholder="Description"
            value={taskDetail.description}
            onChange={(v) => setTaskDetail({ ...taskDetail, description: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Qty</CardHeader>
        <CardBody>
          <Input
            placeholder="Qty"
            value={taskDetail.qty}
            onChange={(v) => setTaskDetail({ ...taskDetail, qty: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Price</CardHeader>
        <CardBody>
          <Input
            placeholder="Price"
            value={taskDetail.price}
            onChange={(v) => setTaskDetail({ ...taskDetail, price: v.target.value })}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Direct Cost</CardHeader>
        <CardBody>
          <Input
            type="select"
            name="select"
            value={taskDetail.serviceFee}
            onChange={(v) => setTaskDetail({ ...taskDetail, serviceFee: v.target.value })}
          >
            <option value={true}>True</option>
            <option value={false}>False</option>
          </Input>
        </CardBody>
      </Card>
      <Button style={{ position: 'fixed', left: 0, bottom: 0 }} color="primary" block onClick={() => _updateConfig()}>
        Save
      </Button>
    </>
  );
}

import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  ButtonGroup,
  Row,
  Col,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
} from 'reactstrap';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../../httpHelper';
import { Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import PageAlertContext from '../../../components/components/PageAlert/PageAlertContext';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import StageText from './StageText';
import LoadingButton from './LoadingButton';
import PopUpContext from '../../../components/components/PopUp/PopUpContext';
import FA from 'react-fontawesome';
import MultiSelect from './MultiSelect';

const generateQuoOrInv = async (type, id, name) => {
  const res = await fetchWithGet(`/projects/generate${type}`, {
    projectId: id,
  });
  if (res.status === 200) {
    const linkSource = `data:application/pdf;base64,${res.data}`;
    const downloadLink = document.createElement('a');
    const fileName = `${name}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
};

const generateReceipt = async (id, paymentId) => {
  const res = await fetchWithGet(`/projects/generateReceipt`, {
    projectId: id,
    paymentId,
  });
  if (res.status === 200) {
    const linkSource = `data:application/pdf;base64,${res.data}`;
    const downloadLink = document.createElement('a');
    const fileName = `${paymentId}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
};

function ProjectDetail({ rootPath, rootName }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  //context
  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  //data
  const [taskList, setTaskList] = useState([]);
  const [projectDetail, setProjectDetail] = useState({});
  const [projectDetailStatus, setProjectDetailStatus] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [adminList, setAdminList] = useState([]);

  //inputs
  const fetchApi = async () => {
    const result = await fetchWithGet('/projects/getProjectDetail', { projectId: id });
    if (result.status === 200) {
      setProjectDetail(result.data);
      setAssignedAdmin(
        (result.data.assignedTo || []).map((v) => {
          return { value: v.id, label: `${v.name} - ${v.email}` };
        })
      );
    }

    const results = await fetchWithGet('/projects/getProjectDetailStatus', { projectId: id });
    if (results.status === 200) {
      setProjectDetailStatus(results.data);
    }

    const users = await fetchWithGet('/users', {
      role: 'vendor',
      sortBy: 'email:asc',
      active: true,
      deleted: false,
    });

    if (users.status === 200) {
      setVendorList(
        users.data.map((v) => {
          return { value: v.id, label: `${v.name} - ${v.email}` };
        })
      );
    }

    const admin = await fetchWithGet('/users', {
      role: 'admin',
      sortBy: 'email:asc',
      active: true,
      deleted: false,
    });

    if (admin.status === 200) {
      const header = _.unionBy(_.flatten(admin.data.map((e) => e.adminGroup)), 'id');
      setAdminList([
        ...header.map((e) => {
          return {
            label: e.groupName,
            options: admin.data
              .filter((i) => {
                return _.includes(
                  i.adminGroup.map((v) => v.id),
                  e.id
                );
              })
              .map((v) => {
                return { value: v.id, label: `${v.name} - ${v.email}` };
              }),
          };
        }),
        {
          label: 'Ungrouped',
          options: admin.data
            .filter((i) => {
              return !i.adminGroup || i.adminGroup.length == 0;
            })
            .map((v) => {
              return { value: v.id, label: `${v.name} - ${v.email}` };
            }),
        },
      ]);
    }

    const taskRes = await fetchWithGet('/tasks?serviceFee=true');
    let tasks = [];
    if (taskRes.status === 200) {
      tasks = [
        ...taskRes.data,
        {
          label: 'Other',
          value: '',
          name: '',
          price: '',
          qty: '',
          serviceFee: true,
          description: '',
          remark: '',
        },
      ];
      setTaskList(tasks);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const documentType = [
    { value: 'payment', label: 'Payment' },
    { value: 'quotation', label: 'Quotation' },
    { value: 'invoice', label: 'Invoice' },
    { value: 'other', label: 'Other' },
  ];
  const [documentModal, setDocumentModal] = useState(false);
  const [document, setDocument] = useState({ documentName: '', documentType: documentType[0], price: 0, file: '' });
  const _uploadDocument = async () => {
    if (!document.file) {
      alertContext.setAlert('Please upload document', 'danger');
      return;
    }

    if (document.documentType.value === 'payment') {
      const toFloat = parseFloat(document.price);
      if (Number.isNaN(toFloat)) {
        alertContext.setAlert('Invalid price', 'danger');
        return;
      }
    }
    const result = await fetchWithFormData('/projects/uploadDocument', {
      projectId: id,
      file: document.file,
      documentType: document.documentType.value,
      documentName: document.documentName,
      parentProjectStatusId: '',
      amount: document.price,
      remark: '',
      paymentDate: document.paymentDate,
      receivedBy: document.receivedBy,
    });
    if (result.status === 200) {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
    setDocumentModal(false);
  };

  const [modal, setModal] = useState(false);
  const [taskId, setTaskId] = useState('');
  const [assignTo, setAssignTo] = useState('');
  const [assignPrice, setAssignPrice] = useState('');
  const [dueDate, setDueDate] = useState('');
  const _assignToVendor = async () => {
    if (assignTo && assignPrice && dueDate) {
      const toFloat = parseFloat(assignPrice);
      if (!Number.isNaN(toFloat)) {
        const result = await fetchWithPost('/projects/assignToVendor', {
          projectId: id,
          assignTo: assignTo.value,
          assignPrice,
          dueDate,
          projectDetailId: taskId,
        });
        if (result.status === 200) {
          alertContext.setAlert('Successfully updated', 'success');
          fetchApi();
        } else {
          alertContext.setAlert(result.data.message, 'danger');
        }
      } else {
        alertContext.setAlert('Invalid price', 'danger');
      }
    } else {
      alertContext.setAlert('Please fill in all the blanks', 'danger');
    }
    setModal(false);
  };

  const [assignedAdmin, setAssignedAdmin] = useState([]);
  const _assignToAdmin = async () => {
    const result = await fetchWithPost('/projects/assignToAdmin', {
      projectId: id,
      assignTo: (assignedAdmin || []).map((v) => v.value),
    });
    if (result.status === 200) {
      alertContext.setAlert('Successfully updated', 'success');
      fetchApi();
    } else {
      alertContext.setAlert(result.data.message, 'danger');
    }
  };

  const [paymentOption, setPaymentOption] = useState('');
  const [remark, setRemark] = useState('');
  const _onConfirm = async () => {
    if (projectDetail.stage === 'quotation_submitted') {
      const res = await fetchWithPost('/projects/confirmOrRejectProject', {
        projectId: id,
        confirm: true,
        remark,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
    } else if (projectDetail.stage === 'payment') {
      if (!paymentOption) {
        alertContext.setAlert('Please fill in all the blanks', 'danger');
        return;
      }

      const res = await fetchWithPost('/projects/confirmOrRejectOutstanding', {
        projectId: id,
        confirm: true,
        remark,
        paymentOption: paymentOption.value,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
      setPaymentOption('');
      setRemark('');
    }
  };
  const _onReject = async () => {
    if (!remark) {
      alertContext.setAlert('Remark cannot be empty', 'danger');
      return;
    }
    if (projectDetail.stage === 'quotation_submitted') {
      const res = await fetchWithPost('/projects/confirmOrRejectProject', {
        projectId: id,
        confirm: false,
        remark,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
    } else if (projectDetail.stage === 'payment') {
      const res = await fetchWithPost('/projects/confirmOrRejectOutstanding', {
        projectId: id,
        confirm: false,
        remark,
        paymentOption: paymentOption.value,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
    }
    setPaymentOption('');
    setRemark('');
  };

  const _onSubmit = async () => {
    const result = await popUpContext.onOpen('Are you confirm to submit this quotation?');
    if (result) {
      if (projectDetail.stage === 'quotation') {
        const res = await fetchWithPost('/projects/submitProject', {
          projectId: id,
        });
        if (res.status === 200) {
          alertContext.setAlert('Successfully updated', 'success');
          fetchApi();
        } else {
          alertContext.setAlert(res.data.message, 'danger');
        }
      } else if (projectDetail.stage === 'payment_pending') {
        const res = await fetchWithPost('/projects/submitOutstanding', {
          projectId: id,
        });
        if (res.status === 200) {
          alertContext.setAlert('Successfully updated', 'success');
          fetchApi();
        } else {
          alertContext.setAlert(res.data.message, 'danger');
        }
      }
    }
  };

  const [commissionType, setCommissionType] = useState('');
  const [commissionValue, setCommissionValue] = useState('');
  const _submitCommission = async () => {
    const result = await popUpContext.onOpen('Are you confirm to submit?');
    if (result) {
      if (!commissionType || !commissionValue) {
        alertContext.setAlert('Please fill in all the blanks', 'danger');
        return;
      }

      if (commissionType.value === 'rate' && (commissionValue < 0 || commissionType > 100)) {
        alertContext.setAlert('Invalid commission value', 'danger');
        return;
      }

      if (commissionType.value === 'fix' && commissionValue < 0) {
        alertContext.setAlert('Invalid commission value', 'danger');
        return;
      }

      const res = await fetchWithPost('/projects/submitComission', {
        projectId: id,
        commissionType: commissionType.value,
        commission: commissionValue,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
      setCommissionType('');
      setCommissionValue('');
    }
  };

  const [directCost, setDirectCost] = useState({
    id: '',
    name: '',
    price: '',
    qty: '',
    taskSelected: '',
    description: '',
    remark: '',
  });
  const [directModal, setDirectModal] = useState(false);
  const _addDirectCost = async () => {
    const result = await popUpContext.onOpen('Are you confirm to create this direct cost record?');
    if (result) {
      delete directCost.taskSelected;
      const res = await fetchWithPost('/projects/directCost', {
        projectId: id,
        ...directCost,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
      setDirectModal(false);
    }
  };
  const _removeDirectCost = async (item) => {
    const { id: directCostId, name, price, qty, description, remark } = item;

    const result = await popUpContext.onOpen('Are you confirm to remove this direct cost record?');
    if (result) {
      const res = await fetchWithPost('/projects/directCost', {
        projectId: id,
        id: directCostId,
        name,
        price,
        qty,
        description,
        remark,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully removed', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
      setDirectModal(false);
    }
  };

  const outstandingType = [
    { label: 'Pending', value: 'pending' },
    { label: 'Completed', value: 'completed' },
  ];
  const [outstandingItem, setOutstandingItem] = useState({
    id: '',
    content: '',
    status: outstandingType[0],
  });
  const [outstandingItemModal, setOutstandingItemModal] = useState(false);
  const _addOutstandingItem = async () => {
    const result = await popUpContext.onOpen('Are you confirm to create this outstanding item?');
    if (result) {
      const res = await fetchWithPost('/projects/addOutstandingTask', {
        projectId: id,
        content: outstandingItem.content,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
      setOutstandingItemModal(false);
    }
  };
  const _updateOutstandingItem = async () => {
    const { id: outstandingId, content, status } = outstandingItem;

    const result = await popUpContext.onOpen('Are you confirm to modify this outstanding item?');
    if (result) {
      const res = await fetchWithPost('/projects/updateOutstandingTask', {
        projectId: id,
        id: outstandingId,
        content,
        status,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
      setOutstandingItemModal(false);
    }
  };
  const _removeOutstandingItem = async (item) => {
    const { id: outstandingId } = item;

    const result = await popUpContext.onOpen('Are you confirm to remove this outstanding item?');
    if (result) {
      const res = await fetchWithPost('/projects/deleteOutstandingTask', {
        projectId: id,
        id: outstandingId,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully removed', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
      setOutstandingItemModal(false);
    }
  };

  const _closeProject = async () => {
    const result = await popUpContext.onOpen('Are you confirm to close this project?');
    if (result) {
      const res = await fetchWithPost('/projects/closeProject', {
        projectId: id,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
    }
  };

  const [voidReason, setVoidReason] = useState('');
  const [voidModal, setVoidModal] = useState(false);
  const _voidProject = async () => {
    const result = await popUpContext.onOpen('Are you confirm to void this project?');
    if (result) {
      const res = await fetchWithPost('/projects/voidProject', {
        id,
        reason: voidReason,
      });
      if (res.status === 200) {
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      } else {
        alertContext.setAlert(res.data.message, 'danger');
      }
    }
    setVoidModal(false);
  };

  useEffect(() => {
    if (!modal) {
      setTaskId('');
      setAssignTo('');
      setAssignPrice('');
      setDueDate('');
    }
    if (!directModal) {
      setDirectCost({
        id: '',
        name: '',
        price: '',
        qty: '',
        taskSelected: '',
        description: '',
        remark: '',
      });
    }
    if (!documentModal) {
      setDocument({ documentName: '', documentType: documentType[0], price: 0, file: '' });
    }
    if (!outstandingItemModal) {
      setOutstandingItem({
        id: '',
        content: '',
        status: 'pending',
      });
    }
    if (!voidModal) {
      setVoidReason('');
    }
  }, [modal, directModal, documentModal, outstandingItemModal, voidModal]);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${id}`}>
            {!_.isEmpty(projectDetail) ? projectDetail.invNo || projectDetail.quoNo || projectDetail.enqNo : id}
          </Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Modal centered={true} isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <b>Assign to vendor</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className="mb-2">Assign To</Label>
            <Select options={vendorList} value={assignTo} onChange={(v) => setAssignTo(v)} />
          </FormGroup>
          <FormGroup>
            <Label>Price</Label>
            <Input placeholder="Price" value={assignPrice} onChange={(v) => setAssignPrice(v.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label>Due Date</Label>
            <DatePicker
              closeOnScroll={true}
              name="dueDate"
              id="dueDate"
              className="form-control d-block"
              selected={dueDate}
              onChange={(date) => setDueDate(date)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => _assignToVendor()}>
            Assign Task
          </Button>
        </ModalFooter>
      </Modal>
      <Modal centered={true} isOpen={directModal} toggle={() => setDirectModal(!directModal)}>
        <ModalHeader toggle={() => setDirectModal(!directModal)}>
          <b>Add Direct Cost</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Select
              options={taskList}
              isSearchable={false}
              placeholder="Name"
              value={directCost.taskSelected}
              onChange={(e) => {
                setDirectCost({
                  taskSelected: e,
                  name: e.name || '',
                  price: e.price,
                  qty: e.qty,
                  description: e.description || '',
                  remark: e.remark || '',
                });
              }}
            />
          </FormGroup>

          {directCost.taskSelected?.value === '' ? (
            <FormGroup>
              <Input
                type="text"
                name="detailName"
                id="detailName"
                placeholder="Name"
                value={directCost.name}
                onChange={(e) => {
                  setDirectCost({ ...directCost, name: e.target.value });
                }}
              />
            </FormGroup>
          ) : null}

          <FormGroup>
            <textarea
              style={{ resize: 'none' }}
              className={'form-control'}
              type="text"
              name="description"
              id="description"
              placeholder="Description"
              rows="4"
              cols="50"
              value={directCost.description}
              onChange={(e) => {
                setDirectCost({ ...directCost, description: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="number"
              name="qty"
              id="qty"
              placeholder="Qty"
              value={directCost.qty}
              onChange={(e) => {
                setDirectCost({ ...directCost, qty: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="number"
              name="price"
              id="price"
              placeholder="Price"
              value={directCost.price}
              onChange={(e) => {
                setDirectCost({ ...directCost, price: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="remark"
              id="remark"
              placeholder="Remark"
              value={directCost.remark}
              onChange={(e) => {
                setDirectCost({ ...directCost, remark: e.target.value });
              }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => _addDirectCost()}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <Modal centered={true} isOpen={documentModal} toggle={() => setDocumentModal(!documentModal)}>
        <ModalHeader toggle={() => setDocumentModal(!documentModal)}>
          <b>Upload Document</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className="mb-2">Document Type</Label>
            <Select
              isSearchable={false}
              options={documentType}
              value={document.documentType}
              onChange={(v) => setDocument({ documentName: '', documentType: v, price: 0, file: '' })}
            />
          </FormGroup>
          <FormGroup>
            <Label>Document Name</Label>
            <Input
              placeholder="Document Name"
              value={document.documentName}
              onChange={(v) => setDocument({ ...document, documentName: v.target.value })}
            />
          </FormGroup>
          {document.documentType.value === 'payment' ? (
            <>
              <FormGroup>
                <Label>Price</Label>
                <Input
                  placeholder="Price"
                  value={document.price}
                  onChange={(v) => setDocument({ ...document, price: v.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Payment Date</Label>
                <DatePicker
                  closeOnScroll={true}
                  name="paymentDate"
                  id="paymentDate"
                  className="form-control"
                  selected={document.paymentDate}
                  onChange={(date) =>
                    setDocument({
                      ...document,
                      paymentDate: date,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Received By</Label>
                <Input
                  placeholder="Received By"
                  value={document.receivedBy}
                  onChange={(v) => setDocument({ ...document, receivedBy: v.target.value })}
                />
              </FormGroup>
            </>
          ) : (
            <></>
          )}
          <FormGroup>
            <Label>Document</Label>
            <Input
              type="file"
              name="doc"
              id="doc"
              onChange={(v) => setDocument({ ...document, file: v.target.files[0] })}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <LoadingButton color="primary" onClick={_uploadDocument} text={'Upload'} />
        </ModalFooter>
      </Modal>
      <Modal
        centered={true}
        isOpen={outstandingItemModal}
        toggle={() => setOutstandingItemModal(!outstandingItemModal)}
      >
        <ModalHeader toggle={() => setOutstandingItemModal(!outstandingItemModal)}>
          <b>{outstandingItem.id ? 'Edit' : 'Add'} Outstanding Item</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="text"
              name="content"
              id="content"
              placeholder="Content"
              value={outstandingItem.content}
              onChange={(e) => {
                setOutstandingItem({ ...outstandingItem, content: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Select
              options={outstandingType}
              value={outstandingType.find((v) => v.value === outstandingItem.status)}
              onChange={(v) => setOutstandingItem({ ...outstandingItem, status: v.value })}
              isDisabled={!outstandingItem.id}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => (outstandingItem.id ? _updateOutstandingItem() : _addOutstandingItem())}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <Modal centered={true} isOpen={voidModal} toggle={() => setVoidModal(!voidModal)}>
        <ModalHeader toggle={() => setVoidModal(!voidModal)}>
          <b>Void Project</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="text"
              name="reason"
              id="reason"
              placeholder="Reason"
              value={voidReason}
              onChange={(e) => {
                setVoidReason(e.target.value);
              }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_voidProject}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      {_.isEmpty(projectDetail) ? (
        <div />
      ) : (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row className={'align-items-center'}>
                    <Col>
                      Stage: <StageText stage={projectDetail.stage} />{' '}
                      {projectDetail.stage === 'void' ? `Reason: ${projectDetail.deleteReason}` : ''}
                    </Col>
                    <Col className={'text-right'}>
                      {projectDetail.stage === 'quotation' || projectDetail.stage === 'payment_pending' ? (
                        <Button className={'mr-2'} color="primary" onClick={_onSubmit}>
                          Submit
                        </Button>
                      ) : (
                        <></>
                      )}
                      {projectDetail.stage === 'quotation' ? (
                        <Button onClick={() => history.push(`${rootPath}/${id}/edit`)}>Edit</Button>
                      ) : (
                        <></>
                      )}
                      {projectDetail.stage === 'quotation' || projectDetail.stage === 'payment_pending' ? (
                        <Button className={'ml-2'} onClick={() => setVoidModal(true)} color="danger">
                          Void Project
                        </Button>
                      ) : (
                        <></>
                      )}
                      {projectDetail.stage === 'wip' ? <Button onClick={_closeProject}>Close Project</Button> : <></>}
                    </Col>
                  </Row>
                  <br />
                  <Row className={'align-items-center'}>
                    <Col></Col>
                    <Col className={'text-right'}>
                      {projectDetail.stage === 'quotation' ? (
                        <>
                          <Button onClick={() => generateQuoOrInv('Quotation', id, projectDetail.quoNo)}>
                            Quotation
                          </Button>
                          {/* <Button onClick={() => generateQuoOrInv('Invoice', id)}>Invoice</Button> */}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>Enquiry No.: {projectDetail.enqNo}</Col>
                    <Col>Prepared by: {projectDetail.createdBy.name}</Col>
                  </Row>
                  <Row>
                    <Col>Quotation No.: {projectDetail.quoNo}</Col>
                    <Col>Prepare For: {projectDetail.userId.name}</Col>
                  </Row>
                  <Row>
                    <Col>Invoice No.: {projectDetail.invNo}</Col>
                    <Col>Created Time: {moment(projectDetail.createdDatetime).format('YYYY-MM-DD')}</Col>
                  </Row>
                  <Row>
                    <Col></Col>
                    <Col>
                      Due Date: {projectDetail.estEndDate && moment(projectDetail.estEndDate).format('YYYY-MM-DD')}
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col>
                      {_.startCase(projectDetail.type)} Name: <br />
                      {projectDetail.name.en}
                      <br />
                      {projectDetail.name.zh}
                    </Col>
                    {projectDetail.commission ? (
                      <Col>
                        {projectDetail.commission ? (
                          <span>
                            Commission:{' '}
                            {projectDetail.commissionType === 'rate'
                              ? `${projectDetail.commission}%`
                              : `$${projectDetail.commission}`}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <Row>
                    <Col>Contact Person: {projectDetail.contactPerson}</Col>
                  </Row>
                  <Row>
                    <Col>Phone No.: {projectDetail.phoneNo}</Col>
                  </Row>
                  <Row>
                    <Col>Email: {projectDetail.email}</Col>
                  </Row>
                  <Row>
                    <Col>
                      Address:
                      <address>{projectDetail.address}</address>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <div className="table-responsive m-b">
                    <table className="table m-t-50">
                      <thead>
                        <tr>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{projectDetail.remark}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div className="table-responsive m-b">
                    <table className="table m-t-50">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Price</th>
                          {projectDetail.stage === 'wip' ? <th>Action</th> : null}
                        </tr>
                      </thead>
                      <tbody>
                        {(projectDetail.details || [])
                          .filter((e) => !e.serviceFee)
                          .map((v, i) => (
                            <tr key={i}>
                              <td width={'10%'}>{i + 1}</td>
                              <td>
                                {v.name}
                                {v.description && (
                                  <>
                                    <br />
                                    <br />
                                    <p>{v.description}</p>
                                  </>
                                )}
                                {v.remark && <p className="small">Remark: {v.remark}</p>}
                              </td>
                              <td>{v.qty}</td>
                              <td>{v.price}</td>
                              {projectDetail.stage === 'wip' ? (
                                <td>
                                  {v.status === 'init' || v.status === 'rejected' ? (
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        setTaskId(v.id);
                                        setModal(!modal);
                                      }}
                                    >
                                      Assign Task
                                    </Button>
                                  ) : (
                                    _.startCase(v.status)
                                  )}
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        <tr>
                          <td colSpan={3} className={'text-right'}>
                            Total Amount HK$
                          </td>
                          <td>{projectDetail.totalAmount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div>
                    <Row className={'align-items-center mb-1'}>
                      <Col>Direct Cost</Col>
                      <Col className={'text-right'}>
                        {projectDetail.stage === 'payment_pending' || projectDetail.stage === 'wip' ? (
                          <Button color="primary" onClick={() => setDirectModal(true)}>
                            <FA name="plus" />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <div className="table-responsive m-b">
                      <table className="table m-t-50">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Price</th>
                            {projectDetail.stage === 'payment_pending' ? <th>Remove</th> : null}
                          </tr>
                        </thead>
                        <tbody>
                          {(projectDetail.details || [])
                            .filter((e) => e.serviceFee)
                            .map((v, i) => (
                              <tr key={i}>
                                <td width={'10%'}>{i + 1}</td>
                                <td>
                                  {v.name}
                                  {v.description && (
                                    <>
                                      <br />
                                      <br />
                                      <p>{v.description}</p>
                                    </>
                                  )}
                                  {v.remark && <p className="small">Remark: {v.remark}</p>}
                                </td>
                                <td>{v.qty}</td>
                                <td>{v.price}</td>
                                {projectDetail.stage === 'payment_pending' ? (
                                  <td>
                                    <Button
                                      color="danger"
                                      className="rounded-circle"
                                      onClick={() => {
                                        _removeDirectCost(v);
                                      }}
                                    >
                                      <FA name="minus" />
                                    </Button>
                                  </td>
                                ) : null}
                              </tr>
                            ))}
                          <tr>
                            <td colSpan={3} className={'text-right'}>
                              Total Direct Cost HK$
                            </td>
                            <td>
                              {(projectDetail.details || [])
                                .filter((e) => e.serviceFee)
                                .reduce((p, v) => p + v.price, 0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div>
                    <CardTitle>Payment History</CardTitle>
                    <div className="table-responsive m-b">
                      <table className="table m-t-50">
                        <thead>
                          <tr>
                            <th>Receipt</th>
                            <th>Payment Id</th>
                            <th>Payment proof</th>
                            <th>Payment Date</th>
                            <th>Received By</th>
                            <th>Uploaded By</th>
                            <th>Upload Datetime</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(projectDetail.payments || []).map((v, i) => (
                            <tr key={i}>
                              <td>
                                <Button
                                  color="primary"
                                  outline
                                  className="mb-2 text-right"
                                  onClick={() => generateReceipt(id, v.paymentId)}
                                >
                                  <FA name={'download'} />
                                </Button>
                              </td>
                              <td>{v.paymentId}</td>
                              <td>
                                <Button color="link" target="_blank" href={v.document.documentPath}>
                                  {v.document.documentName}
                                </Button>
                              </td>
                              <td>{moment(v.paymentDate).format('YYYY-MM-DD')}</td>
                              <td>{v.receivedBy}</td>
                              <td>{v.uploadedBy.name}</td>
                              <td>{moment(v.uploadDatetime).format('YYYY-MM-DD HH:mm')}</td>
                              <td>{v.amount}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={7} className={'text-right'}>
                              Outstanding HK$
                            </td>
                            <td>{projectDetail.outstanding}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div>
                    <Row className={'align-items-center mb-1'}>
                      <Col>Outstanding Item</Col>
                      <Col className={'text-right'}>
                        {projectDetail.stage === 'wip' ? (
                          <Button color="primary" onClick={() => setOutstandingItemModal(true)}>
                            <FA name="plus" />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <div className="table-responsive m-b">
                      <table className="table m-t-50">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Content</th>
                            <th>Status</th>
                            {projectDetail.stage === 'wip' ? <th>Action</th> : null}
                          </tr>
                        </thead>
                        <tbody>
                          {(projectDetail.outstandingTasks || []).map((v, i) => (
                            <tr key={i}>
                              <td width={'10%'}>{i + 1}</td>
                              <td>{v.content}</td>
                              <td>{_.startCase(v.status)}</td>
                              {projectDetail.stage === 'wip' ? (
                                <td>
                                  {v.status === 'pending' ? (
                                    <>
                                      <Button
                                        className="rounded-circle mr-2"
                                        onClick={() => {
                                          setOutstandingItem(v);
                                          setOutstandingItemModal(true);
                                        }}
                                      >
                                        <FA name="edit" />
                                      </Button>
                                      <Button
                                        color="danger"
                                        className="rounded-circle"
                                        onClick={() => {
                                          _removeOutstandingItem(v);
                                        }}
                                      >
                                        <FA name="minus" />
                                      </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardHeader>Project Status</CardHeader>
                <CardBody style={{ overflowY: 'scroll', height: size[1] - 120 }}>
                  <Timeline>
                    {projectDetailStatus.map((v, i) => (
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot />
                          {i === projectDetailStatus.length - 1 ? null : <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          {v.status.en}
                          <br />
                          {v.content ? (
                            <>
                              {v.content} <br />
                            </>
                          ) : (
                            <></>
                          )}
                          {moment(v.createdDatetime).format('YYYY-MM-DD hh:mm')}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              {projectDetail.stage === 'quotation_submitted' ? (
                <Card>
                  <CardHeader>Submission</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Input
                        type="textarea"
                        value={remark}
                        placeholder={'Remark'}
                        className="mb-2"
                        onChange={(e) => setRemark(e.target.value)}
                      />
                      <ButtonGroup style={{ width: '100%' }}>
                        <LoadingButton color="primary" onClick={_onConfirm} text={'Approve'} />
                        <Button color="danger" onClick={_onReject}>
                          Reject
                        </Button>
                      </ButtonGroup>
                    </FormGroup>
                  </CardBody>
                </Card>
              ) : (
                <></>
              )}
              {projectDetail.stage === 'payment' ? (
                <Card>
                  <CardHeader>Confirmation</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Select
                        options={[
                          { label: 'Payment Received - Upload proof', value: 'paid_with_proof' },
                          { label: 'Payment Received - per Salesperson', value: 'paid_only' },
                          { label: 'Pay after job completion', value: 'later' },
                        ]}
                        value={paymentOption}
                        onChange={(v) => setPaymentOption(v)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="textarea"
                        value={remark}
                        placeholder={'Remark'}
                        className="mb-2"
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ButtonGroup style={{ width: '100%' }}>
                        <Button color="primary" onClick={_onConfirm}>
                          Approve
                        </Button>
                        <Button color="danger" onClick={_onReject}>
                          Reject
                        </Button>
                      </ButtonGroup>
                    </FormGroup>
                  </CardBody>
                </Card>
              ) : (
                <></>
              )}
              {projectDetail.stage === 'wip' && !projectDetail.commission ? (
                <Card>
                  <CardHeader>Comission</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Select
                        options={[
                          { label: 'Comission Rate', value: 'rate' },
                          { label: 'Fixed Comission', value: 'fix' },
                        ]}
                        value={commissionType}
                        onChange={(v) => setCommissionType(v)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="number"
                        name="comission"
                        id="comission"
                        placeholder="Comission Value"
                        value={commissionValue}
                        onChange={(e) => setCommissionValue(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ButtonGroup style={{ width: '100%' }}>
                        <Button color="primary" onClick={_submitCommission}>
                          Submit
                        </Button>
                      </ButtonGroup>
                    </FormGroup>
                  </CardBody>
                </Card>
              ) : (
                <></>
              )}
              {projectDetail.stage !== 'closed' && projectDetail.stage !== 'void' ? (
                <Card>
                  <CardHeader>Assign To Admin</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <MultiSelect
                        key="example_id"
                        options={adminList}
                        onChange={(v) => setAssignedAdmin(v)}
                        value={assignedAdmin}
                        isSelectAll={true}
                      />
                      {/* <Select isMulti options={adminList} value={assignedAdmin} onChange={(v) => setAssignedAdmin(v)} /> */}
                    </FormGroup>
                    <FormGroup>
                      <ButtonGroup style={{ width: '100%' }}>
                        <Button color="primary" onClick={_assignToAdmin}>
                          Assign
                        </Button>
                      </ButtonGroup>
                    </FormGroup>
                  </CardBody>
                </Card>
              ) : (
                <></>
              )}
              <Card>
                <CardHeader>Project Documents</CardHeader>
                <CardBody style={{ overflowY: 'scroll', height: size[1] - 120 }}>
                  {projectDetail.folderId ? (
                    <Row className={'align-items-center'}>
                      <Col>
                        <Button
                          color="link"
                          target="_blank"
                          className="mb-2"
                          href={`https://drive.google.com/drive/u/2/folders/${projectDetail.folderId.root}`}
                        >
                          Open Folder in Google
                        </Button>
                      </Col>
                      <Col className={'text-right'}>
                        {projectDetail.stage !== 'closed' && projectDetail.stage !== 'void' ? (
                          <Button
                            color="primary"
                            className="mb-2 text-right"
                            onClick={() => setDocumentModal(!documentModal)}
                          >
                            <FA name={'upload'} />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  ) : null}

                  <div className="table-responsive m-b">
                    <table className="table m-t-50">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Document Name</th>
                          <th>Document Type</th>
                          <th>Uploaded By</th>
                          <th>Upload Datetime</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(projectDetail.documents || []).map((v, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              <Button color="link" target="_blank" href={v.documentPath}>
                                {v.documentName}
                              </Button>
                            </td>
                            <td>{_.startCase(v.documentType)}</td>
                            <td>{v.uploadedBy.name}</td>
                            <td>{moment(v.uploadDatetime).format('YYYY-MM-DD HH:mm')}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default ProjectDetail;

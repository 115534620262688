import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import Cookies from 'js-cookie';
import { fetchWithGet } from '../../httpHelper';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { Loader } from '../../components';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export default function Dashboard() {
  const heroStyles = {
    padding: '0 0 10px',
  };
  const role = Cookies.get('role');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [enquiry, setEnquiry] = useState([]);
  const [project, setProject] = useState([]);
  const [job, setJob] = useState([]);
  const [data, setData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [overDue, setOverDue] = useState([]);
  const [dueDateT7, setDueDateT7] = useState([]);
  const [dueDateT14, setDueDateT14] = useState([]);
  const [tableName, setTableName] = useState('Total Enquiry');
  const columnsOverdue = [
    '#',
    'Name',
    'Price',
    'Due Date',
    'Assign To',
    'Create Date',
    'Confirm or Reject Date',
    'Status',
    'Reject Reason',
  ];
  const optionsOverdue = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      console.log(tasks[rowMeta.dataIndex]);
      history.push(`vendor/jobList/${tasks[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  const columnsEnquiry = [
    '#',
    'Enquiry No.',
    'Quotation No.',
    'Invoice No.',
    'Name',
    'Price',
    'Created By',
    'Due Date',
    'Status',
  ];
  const optionsEnquiry = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      history.push(`project/enquiry/${enquiry[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  const columnsProject = [
    '#',
    'Enquiry No.',
    'Quotation No.',
    'Invoice No.',
    'Name',
    'Price',
    'Paid',
    'Outstanding',
    'Prepared By',
    'Last Update',
    'Due Date',
    'Status',
  ];
  const optionsProject = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      history.push(`project/list/${project[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  const fetchApi = async () => {
    setIsLoading(true);
    if (role === 'broker') {
      const result = await fetchWithGet('/projects/getProjectList');
      if (result) {
        setEnquiry(result.data.results.filter((v) => v.stage === 'enquiry'));
        setProject(result.data.results.filter((v) => v.stage === 'outstanding' && !v?.payments?.length));
        resetTable(
          result.data.results.filter((v) => v.stage === 'enquiry'),
          'Total Enquiry'
        );
      }
      const task = await fetchWithGet('/projects/getOverDueTaskList');
      if (task && task.data) {
        const { overDue, dueDateT7, dueDateT14 } = task.data;
        if (overDue) setOverDue(overDue);
        if (dueDateT7) setDueDateT7(dueDateT7);
        if (dueDateT14) setDueDateT14(dueDateT14);
      }
    }
    if (role === 'vendor') {
      const result = await fetchWithGet('/projects/getTaskList');
      if (result) {
        setJob(result.data.filter((v) => moment().isSame(v.createdDatetime, 'day')));
      }
    }
    setIsLoading(false);
  };

  const resetTable = (data, tableName) => {
    setTableName(tableName);
    if (['OverDue', 'Due Date (T-7)', 'Due Date (T-14)'].includes(tableName)) {
      setTasks(data);
      setData(
        data.map((v, i) => [
          i + 1,
          v.projectDetailId.name,
          v.assignPrice,
          v.dueDate && moment(v.dueDate).format('YYYY-MM-DD'),
          v.assignTo.name,
          v.createdDatetime && moment(v.createdDatetime).format('YYYY-MM-DD'),
          v.confirmOrRejectDatetime && moment(v.confirmOrRejectDatetime).format('YYYY-MM-DD'),
          _.startCase(v.status),
          v.rejectReason,
        ])
      );
    } else if (tableName === 'Total Enquiry') {
      setData(
        data.map((v, i) => [
          i + 1,
          v.enqNo || '',
          v.quoNo || '',
          v.invNo || '',
          v.name,
          v.totalAmount,
          v.userId.name,
          v.estEndDate && moment(v.estEndDate).format('YYYY-MM-DD'),
          _.startCase(v.stage),
        ])
      );
    } else if (tableName === 'Unpaid Project') {
      setData(
        data.map((v, i) => [
          i + 1,
          v.enqNo || '',
          v.quoNo || '',
          v.invNo || '',
          v.name,
          v.totalAmount,
          v.outstanding || 0,
          v.totalAmount - (v.outstanding || 0),
          v.userId.name,
          v.lastUpdateStatusTime && moment(v.lastUpdateStatusTime).format('YYYY-MM-DD hh:mm:ss'),
          v.estEndDate && moment(v.estEndDate).format('YYYY-MM-DD'),
          _.startCase(v.stage),
        ])
      );
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <div>
      <Row>
        <Col md={12}>
          <div className="home-hero" style={heroStyles}>
            <h1>Welcome to Step First Account Limited</h1>
            {/* <p className="text-muted">Here's what's going on with your today.</p> */}
          </div>
        </Col>
      </Row>
      <Row>
        {role === 'broker' ? (
          <>
            <Col md={4} sm={12}>
              <Card>
                <CardHeader>
                  Total Enquiry{' '}
                  <Button size="sm" className="pull-right" onClick={() => resetTable(enquiry, 'Total Enquiry')}>
                    View
                  </Button>
                </CardHeader>
                <CardBody>
                  <h2 className="m-b-20 inline-block">
                    <span>{enquiry.length}</span>
                  </h2>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card>
                <CardHeader>
                  Unpaid Project{' '}
                  <Button size="sm" className="pull-right" onClick={() => resetTable(project, 'Unpaid Project')}>
                    View
                  </Button>
                </CardHeader>
                <CardBody>
                  <h2 className="m-b-20 inline-block">
                    <span>{project.length}</span>
                  </h2>
                </CardBody>
              </Card>
            </Col>
          </>
        ) : null}

        {/* {role === 'vendor' || role === 'broker' ? (
          <>
            <Col md={4} sm={12}>
              <Card>
                <CardHeader>
                  Today job{' '}
                  <Button size="sm" className="pull-right" href={'/vendor/jobList'}>
                    View
                  </Button>
                </CardHeader>
                <CardBody>
                  <h2 className="m-b-20 inline-block">
                    <span>{job.length}</span>
                  </h2>
                </CardBody>
              </Card>
            </Col>
          </>
        ) : null} */}
      </Row>

      {role === 'broker' ? (
        <>
          <Row>
            <Col md={4} sm={12}>
              <Card>
                <CardHeader>
                  OverDue{' '}
                  <Button size="sm" className="pull-right" onClick={() => resetTable(overDue, 'OverDue')}>
                    View
                  </Button>
                </CardHeader>
                <CardBody>
                  <h2 className="m-b-20 inline-block">
                    <span>{overDue.length}</span>
                  </h2>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card>
                <CardHeader>
                  Due Date (T-7){' '}
                  <Button size="sm" className="pull-right" onClick={() => resetTable(dueDateT7, 'Due Date (T-7)')}>
                    View
                  </Button>
                </CardHeader>
                <CardBody>
                  <h2 className="m-b-20 inline-block">
                    <span>{dueDateT7.length}</span>
                  </h2>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card>
                <CardHeader>
                  Due Date (T-14){' '}
                  <Button size="sm" className="pull-right" onClick={() => resetTable(dueDateT14, 'Due Date (T-14)')}>
                    View
                  </Button>
                </CardHeader>
                <CardBody>
                  <h2 className="m-b-20 inline-block">
                    <span>{dueDateT14.length}</span>
                  </h2>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <p className="text-muted">{tableName}</p>
          {['OverDue', 'Due Date (T-7)', 'Due Date (T-14)'].includes(tableName) ? (
            <MUIDataTable data={data} columns={columnsOverdue} options={optionsOverdue} />
          ) : null}
          {tableName === 'Total Enquiry' ? (
            <MUIDataTable data={data} columns={columnsEnquiry} options={optionsEnquiry} />
          ) : null}
          {tableName === 'Unpaid Project' ? (
            <MUIDataTable data={data} columns={columnsProject} options={optionsProject} />
          ) : null}
        </>
      ) : null}
    </div>
  );
}

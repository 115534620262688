import React from 'react';
import { Badge } from 'reactstrap';

export default function StageText({ stage }) {
  const stageMapping = {
    enquiry: 'Enquiry',
    enquiry_failed: 'Enquiry Failed',
    quotation: 'Quotation',
    quotation_submitted: 'Quotation Submitted',
    payment_pending: 'Payment Pending',
    payment: 'Payment',
    wip: 'Work in Progress',
    closed: 'Closed',
    void: 'Void',
  };

  return stageMapping[stage] ? <Badge color={'info'}>{stageMapping[stage]}</Badge> : <></>;
}

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './layouts/Login';
import './components/scss/styles.scss';
import 'react-datetime/css/react-datetime.css';

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route component={DashboardLayout} />
      </Switch>
    </BrowserRouter>
  );
}

import React, { useState } from 'react';
import { Button } from 'reactstrap';

export default function LoadingButton({ color, onClick, text }) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const _onClick = async () => {
    setButtonLoading(true);
    await onClick();
    setButtonLoading(false);
  };
  return (
    <Button color={color} onClick={_onClick} disabled={buttonLoading}>
      {buttonLoading ? <i className="fa fa-refresh fa-spin" style={{ marginRight: '5px' }} /> : <></>}
      {text}
    </Button>
  );
}

import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  ButtonGroup,
  Row,
  Col,
  Table,
} from 'reactstrap';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import MUIDataTable from 'mui-datatables';
import Cookies from 'js-cookie';
import { Loader, Chat } from '../../components';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import StageText from './widgets/StageText';

export default function EnquiryListPage() {
  let { path, url } = useRouteMatch();
  const rootName = 'Enquiry List';
  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <EnquiryList rootPath={url} rootName={rootName} />
        </Route>
        <Route path={`${path}/:id`}>
          <EnquiryDetail rootPath={url} rootName={rootName} />
        </Route>
      </Switch>
    </Router>
  );
}

function EnquiryList({ rootPath, rootName }) {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const columns = [
    '#',
    'Enquiry No.',
    'Quotation No.',
    'Invoice No.',
    'Name',
    'Price',
    'Created By',
    'Due Date',
    'Status',
  ];
  const options = {
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => {
      history.push(`${rootPath}/${projects[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet(`/projects/getProjectList`);
    if (result) {
      const enqList = result.data.results.filter((v) => v.stage === 'enquiry');
      setProjects(enqList);
      setData(
        enqList.map((v, i) => [
          i + 1,
          v.enqNo || '',
          v.quoNo || '',
          v.invNo || '',
          v.name,
          v.totalAmount,
          v.userId.name,
          v.estEndDate && moment(v.estEndDate).format('YYYY-MM-DD'),
          _.startCase(v.stage),
        ])
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function EnquiryDetail({ rootPath, rootName }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  //context
  const alertContext = useContext(PageAlertContext);

  //data
  const [projectDetail, setProjectDetail] = useState({});
  const [projectDetailStatus, setProjectDetailStatus] = useState([]);
  const [stage, setStage] = useState({ label: 'Enquiry', value: 'enquiry' });

  //inputs
  const fetchApi = async () => {
    const result = await fetchWithGet('/projects/getProjectDetail', { projectId: id });
    if (result.status === 200) {
      setProjectDetail(result.data);
      setStage({ label: _.startCase(result.data.stage), value: result.data.stage });
    }

    const results = await fetchWithGet('/projects/getProjectDetailStatus', { projectId: id });
    if (results.status === 200) {
      setProjectDetailStatus(results.data);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const updateEnquiryStage = async (stage) => {
    if (stage && stage.value) {
      const result = await fetchWithPost('/projects/updateEnquiryStage', {
        id,
        stage: stage.value,
      });
      if (result && result.status === 200) {
        setStage(stage);
        alertContext.setAlert('Successfully updated', 'success');
        fetchApi();
      }
    }
  };

  const [content, setContent] = useState('');
  const _updateTaskStatus = async () => {
    if (content) {
      const result = await fetchWithPost('/projects/addProjectStatus', {
        projectId: id,
        content,
        statusType: 'enquiry',
      });
      if (result && result.status === 200) {
        setContent('');
        fetchApi();
      }
    }
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>{rootName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${id}`}>{!_.isEmpty(projectDetail) ? projectDetail.name : id}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      {_.isEmpty(projectDetail) ? (
        <div />
      ) : (
        <>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className={'align-items-center'}>
                    <Col>
                      Stage: <StageText stage={projectDetail.stage} />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col>Enquiry No.: {projectDetail.enqNo}</Col>
                    <Col>Prepared by: {projectDetail.userId.name}</Col>
                  </Row>
                  <Row>
                    <Col>Quotation No.: {projectDetail.quoNo}</Col>
                    <Col>Created Time: {moment(projectDetail.createdDatetime).format('YYYY-MM-DD')}</Col>
                  </Row>
                  <Row>
                    <Col>Invoice No.: {projectDetail.invNo}</Col>
                    <Col>
                      Due Date: {projectDetail.estEndDate && moment(projectDetail.estEndDate).format('YYYY-MM-DD')}
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col>Company/Customer: {projectDetail.name}</Col>
                  </Row>
                  <Row>
                    <Col>Contact Person: {projectDetail.contactPerson}</Col>
                  </Row>
                  <Row>
                    <Col>Phone No.: {projectDetail.phoneNo}</Col>
                  </Row>
                  <Row>
                    <Col>Email: {projectDetail.email}</Col>
                  </Row>
                  <Row>
                    <Col>
                      Address:
                      <address>{projectDetail.address}</address>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <div className="table-responsive m-b">
                    <table className="table m-t-50">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(projectDetail.details || []).map((v, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {v.name}
                              {v.description && (
                                <>
                                  <br />
                                  <br />
                                  <p>{v.description}</p>
                                </>
                              )}
                              {v.remark && <p className="small">Remark: {v.remark}</p>}
                            </td>
                            <td>{v.qty}</td>
                            <td>{v.price}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={3} className={'text-right'}>
                            Total Amount HK$
                          </td>
                          <td>{projectDetail.totalAmount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {projectDetail.stage === 'enquiry' ? (
                <Card>
                  <CardHeader>Update Stage</CardHeader>
                  <CardBody>
                    <Select
                      isSearchable={false}
                      options={[
                        { label: 'Enquiry', value: 'enquiry' },
                        { label: 'Enquiry Failed', value: 'enquiry_failed' },
                      ]}
                      value={stage}
                      onChange={(v) => updateEnquiryStage(v)}
                    />
                    <br />
                  </CardBody>
                </Card>
              ) : (
                <></>
              )}
              <Card>
                <CardHeader>Update status</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Input
                      type="textarea"
                      value={content}
                      placeholder={'Content'}
                      className="mb-2"
                      onChange={(e) => setContent(e.target.value)}
                    />
                    <Button color="primary" onClick={() => _updateTaskStatus()} block>
                      Update
                    </Button>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardHeader>Project Status</CardHeader>
                <CardBody style={{ overflowY: 'scroll', height: size[1] - 500 }}>
                  <Timeline>
                    {projectDetailStatus.map((v, i) => (
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot />
                          {i === projectDetailStatus.length - 1 ? null : <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          {v.status.en}
                          <br />
                          {v.content ? (
                            <>
                              {v.content} <br />
                            </>
                          ) : (
                            <></>
                          )}
                          {moment(v.createdDatetime).format('YYYY-MM-DD hh:mm')}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
